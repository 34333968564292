import auth, { FirebaseAuthTypes } from "@react-native-firebase/auth";
import { StackScreenProps } from "@react-navigation/stack";
import React, { useState } from "react";

import { SignInFormState, SignInScreenProps } from "./sign-in.screen";
import { AppStackParamList } from "../../navigation/navigators/app-stack.navigator";
import { Routes } from "../../navigation/routes";
import { dlToast } from "../core/utils/toast.util";

const initialState: SignInFormState = {
  email: "",
  password: "",
};

type ContainerProps = StackScreenProps<AppStackParamList, Routes.AUTH_STACK__SIGN_IN>;

export const SignInContainer = (Screen: React.FC<SignInScreenProps>) =>
  function _(props: ContainerProps) {
    const [formState, setFormState] = useState<SignInFormState>(initialState);
    const [isSigningIn, setIsSigningIn] = useState(false);

    return (
      <Screen
        isSigningIn={isSigningIn}
        formState={formState}
        onChangeFormState={setFormState}
        onSignIn={async () => {
          try {
            const { email, password } = formState;
            if (!email || !password) {
              throw new Error("Invalid email or password");
            }
            setIsSigningIn(true);
            await auth().signInWithEmailAndPassword(email, password);
          } catch (e) {
            const error = e as FirebaseAuthTypes.NativeFirebaseAuthError;
            dlToast.error(error.message);
          } finally {
            setIsSigningIn(false);
          }
        }}
        onGoToSignUp={() => {
          props.navigation.navigate(Routes.AUTH_STACK__SIGN_UP);
        }}
        onGoToForgotPassword={() => {
          props.navigation.navigate(Routes.AUTH_STACK__FORGOT_PWD);
        }}
        // onGoToDebugEnv={() => {
        //   props.navigation.navigate(Routes.AUTH_STACK__DEBUG_ENV);
        // }}
      />
    );
  };
