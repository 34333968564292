import { useFocusEffect } from "@react-navigation/native";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ChatScreenProps } from "./chat.screen";
import { selectChatMessages } from "./chat.slice";
import { useChatControllerPostMutation } from "../../services/api/api-service";
import { dbService } from "../../services/db-service";
import { useCurrentPlayer } from "../core/hooks/use-current-player";
import { dlToast } from "../core/utils/toast.util";

export const ChatContainer = (Screen: React.FC<ChatScreenProps>) =>
  function _() {
    const dispatch = useDispatch();
    const { player } = useCurrentPlayer();
    const [postChat, { isLoading }] = useChatControllerPostMutation();

    const chatMessages = useSelector(selectChatMessages);

    useFocusEffect(
      useCallback(() => {
        const { path, onValueChange } = dbService.getChatMessages({ dispatch });
        // Stop listening for updates when no longer required
        return () => dbService.cleanupValueEvent(path, onValueChange);
      }, [dispatch]),
    );

    return (
      <Screen
        player={player}
        chatMessages={chatMessages}
        isSending={isLoading}
        onSendMessage={async (message, { onSuccess }) => {
          if (!player || !message.length) return;
          try {
            await postChat({
              chatBoardId: "global",
              createChatMessageDto: {
                playerId: player.id,
                avatar: player.avatar,
                name: player.name,
                message,
              },
            }).unwrap();
            onSuccess();
          } catch (e: any) {
            dlToast.error(e.message || "Failed to send chat");
          }
        }}
      />
    );
  };
