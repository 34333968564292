import clsx from "clsx";
import React, { useState } from "react";
import { ActivityIndicator, Image, ImageProps, View } from "react-native";

import { images } from "../../../../assets/images";
import { colors } from "../../../tw-config";

export interface SafeImageProps extends ImageProps {
  fallback: "avatar" | "generic";
}

export const SafeImage = ({ fallback, source, className, ...restProps }: SafeImageProps) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <View className={clsx(className, "relative")}>
      <Image
        {...restProps}
        source={source}
        defaultSource={images.genericFolk}
        onLoadStart={() => {
          setIsLoading(true);
        }}
        onLoadEnd={() => {
          setIsLoading(false);
        }}
      />
      {isLoading && (
        <View className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center">
          <ActivityIndicator color={colors.gray[300]} />
        </View>
      )}
    </View>
  );
};
