import clsx from "clsx";
import React from "react";
import { View, ViewProps } from "react-native";

import { Heading } from "./heading";
import { ViewPropsCls } from "../types/class-name";

export interface HeaderCardProps extends ViewPropsCls {
  title: string;
  _header?: ViewProps;
}

/**
 * Card with a header component
/*
 * +-------------+
 * | Header      |
 * +-------------+
 * | Body        |
 * |             |
 * +-------------+
 */
export const HeaderCard = ({ title, children, _header, ...containerProps }: HeaderCardProps) => {
  return (
    <View
      {...containerProps}
      className={clsx(["rounded-3xl bg-oppsBlack overflow-hidden", containerProps._className])}>
      <View {..._header} className={clsx(["p-5 items-center bg-yellow-600", _header?.className])}>
        <Heading weight="bold" _className="text-2xl">
          {title}
        </Heading>
      </View>
      <View className="p-5 space-y-5">{children}</View>
    </View>
  );
};
