import React, { useState } from "react";
import { ImageBackground, View } from "react-native";

import { ComeOutResult } from "./components/come-out-result";
import { DiceRollResult } from "./components/dice-roll-result";
import { Face } from "./components/die";
import { DieRoll } from "./components/die-roll";
import { Scoreboard } from "./components/scoreboard";
import { getLatestRoll } from "./game.utils";
import { images } from "../../../assets/images";
import { GameDto } from "../../models/game";
import { Background } from "../core/components/background";
import { Button } from "../core/components/button";

export interface GameScreenProps {
  game: GameDto;
  isLoading: boolean;
  diceRolling: boolean;
  onShoot: () => void;
  onNext: () => void;
}

export const GameScreen = (props: GameScreenProps) => {
  return (
    <Background>
      {/* Scoreboard */}
      <Scoreboard game={props.game} />
      {/* Game field */}
      <View className="flex flex-1 overflow-hidden rounded-3xl">
        <ImageBackground source={images.gameboardBg} resizeMode="cover" className="w-full h-full">
          {(() => {
            if (!props.game.comeOut) {
              return null;
            } else if (props.diceRolling) {
              const roll = getLatestRoll(props.game);
              if (!roll) return null;
              return (
                <View className="flex flex-row items-center justify-center w-full h-full p-5 space-x-12 bg-blue-900/75">
                  <DieRoll roll={roll.dice1 as Face} _className="w-20 h-20 p-2" />
                  <DieRoll roll={roll.dice2 as Face} _className="w-20 h-20 p-2" />
                </View>
              );
            } else if (!props.game.gameOver && props.game.rolls?.length === 1) {
              return <ComeOutResult />;
            }
            return <DiceRollResult />;
          })()}
        </ImageBackground>
      </View>
      {(() => {
        if (props.diceRolling)
          return (
            <Button variant="solid" disabled>
              SHOOT!
            </Button>
          );
        if (!props.game.gameOver) {
          return (
            <Button
              variant="solid"
              onPress={props.onShoot}
              isLoading={props.isLoading}
              disabled={props.isLoading}>
              {props.game.comeOut ? "SHOOT, AGAIN!" : "SHOOT!"}
            </Button>
          );
        }
        return (
          <Button
            variant="solid"
            onPress={props.onNext}
            isLoading={props.isLoading}
            disabled={props.isLoading}>
            NEXT
          </Button>
        );
      })()}
    </Background>
  );
};
