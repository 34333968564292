import classNames from "clsx";
import React, { FC, useState } from "react";
import { View } from "react-native";

import { ViewPropsCls } from "../../core/types/class-name";

export type Face = 1 | 2 | 3 | 4 | 5 | 6;

interface DotProps extends ViewPropsCls {
  size?: number;
}

const Blank = ({ size, style }: DotProps) => (
  <View className="bg-white rounded-full" style={[{ width: size, height: size }, style]} />
);

const Dot = ({ size, style }: DotProps) => (
  <View className="bg-black rounded-full" style={[{ width: size, height: size }, style]} />
);

const DotGroup = (props: ViewPropsCls) => (
  <View className="flex items-center justify-between flex-1 space-y-2" {...props} />
);

const DOTS: Record<Face, FC<DotProps>> = {
  1: ({ size }) => (
    <>
      <DotGroup>
        <Blank size={size} />
        <Blank size={size} />
        <Blank size={size} />
      </DotGroup>

      <DotGroup>
        <Blank size={size} />
        <Dot size={size} />
        <Blank size={size} />
      </DotGroup>

      <DotGroup>
        <Blank size={size} />
        <Blank size={size} />
        <Blank size={size} />
      </DotGroup>
    </>
  ),
  2: ({ size }) => (
    <>
      <DotGroup>
        <Dot size={size} />
        <Blank size={size} />
        <Blank size={size} />
      </DotGroup>

      <DotGroup>
        <Blank size={size} />
        <Blank size={size} />
        <Blank size={size} />
      </DotGroup>

      <DotGroup>
        <Blank size={size} />
        <Blank size={size} />
        <Dot size={size} />
      </DotGroup>
    </>
  ),
  3: ({ size }) => (
    <>
      <DotGroup>
        <Blank size={size} />
        <Blank size={size} />
        <Dot size={size} />
      </DotGroup>

      <DotGroup>
        <Blank size={size} />
        <Dot size={size} />
        <Blank size={size} />
      </DotGroup>

      <DotGroup>
        <Dot size={size} />
        <Blank size={size} />
        <Blank size={size} />
      </DotGroup>
    </>
  ),
  4: ({ size }) => (
    <>
      <DotGroup>
        <Dot size={size} />
        <Blank size={size} />
        <Dot size={size} />
      </DotGroup>

      <DotGroup>
        <Blank size={size} />
        <Blank size={size} />
        <Blank size={size} />
      </DotGroup>

      <DotGroup>
        <Dot size={size} />
        <Blank size={size} />
        <Dot size={size} />
      </DotGroup>
    </>
  ),
  5: ({ size }) => (
    <>
      <DotGroup>
        <Dot size={size} />
        <Blank size={size} />
        <Dot size={size} />
      </DotGroup>

      <DotGroup>
        <Blank size={size} />
        <Dot size={size} />
        <Blank size={size} />
      </DotGroup>

      <DotGroup>
        <Dot size={size} />
        <Blank size={size} />
        <Dot size={size} />
      </DotGroup>
    </>
  ),
  6: ({ size }) => (
    <>
      <DotGroup>
        <Dot size={size} />
        <Dot size={size} />
        <Dot size={size} />
      </DotGroup>

      <DotGroup>
        <Blank size={size} />
        <Blank size={size} />
        <Blank size={size} />
      </DotGroup>

      <DotGroup>
        <Dot size={size} />
        <Dot size={size} />
        <Dot size={size} />
      </DotGroup>
    </>
  ),
};

export interface DieProps extends ViewPropsCls {
  value: Face;
}

export const Die = (props: DieProps) => {
  const [width, setWidth] = useState(0);

  const DotComponent = DOTS[props.value];

  const dotWidth = width * 0.2;

  return (
    <View
      className={classNames(
        "flex flex-row items-center justify-center bg-white rounded-lg shadow-md shadow-black",
        props._className,
      )}
      onLayout={(e) => {
        const { width } = e.nativeEvent.layout;
        setWidth(width);
      }}
      {...props}>
      <DotComponent size={dotWidth} />
    </View>
  );
};
