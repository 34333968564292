import React, { useRef, useState } from "react";
import { ScrollView, View } from "react-native";

import { ChatBubble } from "./chat-bubble";
import { ChatInput, ChatInputProps } from "./chat-input";
import { ChatMessage } from "./chat.type";
import { images } from "../../../assets/images";
import { Player } from "../../models/player";
import { Background } from "../core/components/background";
import { SafeImage } from "../core/components/safe-image";
import { ProfileCard } from "../profile/components/profile-card";

export interface ChatScreenProps {
  player: Player | null;
  chatMessages: ChatMessage[];
  isSending: boolean;
  onSendMessage: ChatInputProps["onSend"];
}

export const ChatScreen = (props: ChatScreenProps) => {
  const scrollViewRef = useRef<ScrollView>(null);

  const [autoscroll, setAutoscroll] = useState(true);

  function scrollToBottom() {
    scrollViewRef.current?.scrollToEnd({ animated: true });
  }

  return (
    <Background keyboardAvoiding>
      <View className="flex items-center basis-2/12">
        {props.player && (
          <ProfileCard
            name={props.player.name}
            avatar={props.player.avatar}
            horizontal
            _className="flex flex-1 w-full"
            ending={
              <View className="flex items-end self-end flex-1">
                <SafeImage
                  fallback="generic"
                  className="h-full rounded-r-lg aspect-square"
                  source={images.logoBlack}
                />
              </View>
            }
          />
        )}
      </View>
      <ScrollView
        ref={scrollViewRef}
        className="p-5 space-y-2 basis-9/12 rounded-3xl bg-oppsBlack"
        onScroll={(event) => {
          const { contentOffset, contentSize, layoutMeasurement } = event.nativeEvent;
          // Calculate the bottom position based on current scroll position and layout size
          const bottomPosition = contentOffset.y + layoutMeasurement.height;
          // bottomOffset: how far away from the bottom the scroll is to determine that it's out of view
          // Ideally, should be the size a 1 or 2-liner chat bubble, but this is fine for now
          const bottomOffset = 200; // dp
          // Check if the bottom is not visible
          if (bottomPosition < contentSize.height - bottomOffset) {
            // User has scrolled up, disable autoscroll to bottom on new message
            setAutoscroll(false);
            // This would probaby cause a memory leak if the screen is unmounted while the timeout is still running, but it's fine for now.
            setTimeout(() => {
              if (!autoscroll) {
                setAutoscroll(true);
              }
            }, 90000); // 90 seconds
          } else {
            setAutoscroll(true);
          }
        }}
        scrollEventThrottle={300}
        onContentSizeChange={() => {
          if (autoscroll) {
            scrollToBottom();
          }
        }}>
        {props.chatMessages?.map((chat) => {
          if (!chat) return null;
          const isUser = chat.playerId === props.player?.id;
          return (
            <ChatBubble
              key={chat.id}
              type={isUser ? "user" : "player"}
              avatar={{ uri: chat.avatar }}
              name={chat.name}
              message={chat.message}
            />
          );
        })}
        <View className="h-12" />
      </ScrollView>
      <ChatInput
        onSend={(...args) => {
          scrollToBottom();
          props.onSendMessage(...args);
        }}
        isLoading={props.isSending}
      />
    </Background>
  );
};
