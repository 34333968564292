import auth from "@react-native-firebase/auth";
import { StackScreenProps } from "@react-navigation/stack";
import React from "react";
import { useDispatch } from "react-redux";

import { ProfileScreenProps } from "./profile.screen";
import { ProfileStackParamList } from "../../navigation/navigators/profile-stack.navigator";
import { Routes } from "../../navigation/routes";
import { loggedOut } from "../../store/store";
import { useCurrentPlayer } from "../core/hooks/use-current-player";

type ContainerProps = StackScreenProps<ProfileStackParamList, Routes.PROFILE_STACK__PROFILE>;

export const ProfileContainer = (Screen: React.FC<ProfileScreenProps>) =>
  function _(props: ContainerProps) {
    const dispatch = useDispatch();

    const { player } = useCurrentPlayer();

    return (
      <Screen
        player={player}
        onEditProfile={() => {
          props.navigation.navigate(Routes.PROFILE_STACK__EDIT_PROFILE);
        }}
        onSignOut={async () => {
          await auth().signOut();
          dispatch(loggedOut());
        }}
      />
    );
  };
