import { BottomTabBarProps } from "@react-navigation/bottom-tabs";
import React from "react";
import { TouchableOpacity, View } from "react-native";

import { Paragraph } from "../../modules/core/components/paragraph";
import { colors, theme } from "../../tw-config";

const TAB_BAR_HEIGHT = 80;

export interface HomeTabBarProps extends BottomTabBarProps {}

export const HomeTabBar = ({ state, navigation, descriptors, insets }: HomeTabBarProps) => {
  return (
    <View
      className="flex-row bg-oppsBlack"
      style={{
        paddingBottom: insets.bottom,
      }}>
      {state.routes.map((route, index) => {
        const { options } = descriptors[route.key];
        const label =
          options.tabBarLabel !== undefined && typeof options.tabBarLabel === "string"
            ? options.tabBarLabel
            : options.title !== undefined
            ? options.title
            : route.name;

        const isFocused = state.index === index;

        const onPress = () => {
          const event = navigation.emit({
            type: "tabPress",
            target: route.key,
            canPreventDefault: true,
          });
          if (!isFocused && !event.defaultPrevented) {
            // @ts-ignore - The `merge: true` option makes sure that the params inside the tab screen are preserved
            navigation.navigate({ name: route.name, merge: true });
          }
        };

        const onLongPress = () => {
          navigation.emit({
            type: "tabLongPress",
            target: route.key,
          });
        };

        const color = (isFocused ? colors.oppsRed : colors.oppsGold) as string;

        return (
          <TouchableOpacity
            key={route.name}
            accessibilityRole="button"
            accessibilityState={isFocused ? { selected: true } : {}}
            accessibilityLabel={options.tabBarAccessibilityLabel}
            testID={options.tabBarTestID}
            onPress={onPress}
            onLongPress={onLongPress}
            className="items-center justify-center flex-1 pt-3 pb-4 space-y-1"
            style={{ height: TAB_BAR_HEIGHT }}>
            {options.tabBarIcon?.({ focused: isFocused, color, size: 24 })}
            <Paragraph _className="text-xs tracking-tighter" style={{ color }}>
              {label}
            </Paragraph>
            {isFocused && <View className="absolute w-full h-1 bg-oppsRed -top-1" />}
          </TouchableOpacity>
        );
      })}
    </View>
  );
};
