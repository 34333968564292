export const loseTrashTalks = [
  {
    id: "7a5c4403-b2a4-4755-b5da-2d88792fb83a",
    message: "Turned you into a PACK.  Get on up outta here!",
    imageMobile: require("../../../assets/images/0pps-individual.png"),
    imageDesktop: require("../../../assets/images/0pps-individual-desktop.png"),
    severity: 1,
    weight: 1,
  },
  {
    id: "c18f2e23-3b72-45f2-bb6a-1f79a145ee9e",
    message: "Gimme my money!!!! Who else want some?!?!",
    imageMobile: require("../../../assets/images/0pps-individual.png"),
    imageDesktop: require("../../../assets/images/0pps-individual-desktop.png"),
    severity: 1,
    weight: 1,
  },
  {
    id: "f6ea4a38-4dab-408c-877d-2b313bfcb5d6",
    message: "We put belt to ass!  Don't cry.",
    imageMobile: require("../../../assets/images/0pps-horde-zoom.png"),
    imageDesktop: require("../../../assets/images/0pps-horde-desktop.png"),
    severity: 1,
    weight: 1,
  },
  {
    id: "20a57ec9-d9c5-4fb3-a11f-ef78258de8e6",
    message: "Take this L ...witcho goofy ass.",
    imageMobile: require("../../../assets/images/0pps-individual.png"),
    imageDesktop: require("../../../assets/images/0pps-individual-desktop.png"),
    severity: 1,
    weight: 1,
  },
];
