import React from "react";
import { View } from "react-native";
import { useSelector } from "react-redux";

import { Button } from "../../core/components/button";
import { Heading } from "../../core/components/heading";
import { Paragraph } from "../../core/components/paragraph";
import { selectCurrentGame } from "../game.slice";
import { getLosingRolls, getWinningRolls } from "../game.utils";

export interface BetConfirmationProps {
  onAcknowledge: () => void;
}

export const BetConfirmation = (props: BetConfirmationProps) => {
  const game = useSelector(selectCurrentGame);

  if (!game) return null;
  return (
    <View className="justify-center flex-1">
      <View className="space-y-4">
        <View className="flex items-center p-5 space-y-4 bg-black rounded-3xl">
          <Heading weight="bold" _className="text-3xl text-red-600">
            The Bet
          </Heading>
          <View className="flex flex-row items-center self-stretch border-2 border-yellow-600 rounded-lg">
            <View className="flex items-center justify-center flex-1 p-3 bg-yellow-600">
              <Paragraph weight="bold" _className="text-black text-lg text-center">
                {game?.betType}
              </Paragraph>
            </View>
            <View className="flex flex-row items-end justify-center space-x-1 basis-2/3">
              <Heading weight="bold" _className="text-oppsGold text-2xl">
                {game?.betAmount}
              </Heading>
              <Paragraph _className="pb-1 text-oppsGold">ZBK</Paragraph>
            </View>
          </View>
          <View className="flex flex-row self-stretch border-2 border-red-600 rounded-lg">
            <View className="flex items-center justify-center flex-1 px-3 py-5 bg-red-600">
              <Paragraph _className="text-black text-lg text-center leading-6" weight="bold">
                Winning{"\n"}Rolls
              </Paragraph>
              <Heading _className="text-black text-3xl text-center" weight="bold">
                {getWinningRolls(game)}
              </Heading>
            </View>
            <View className="flex items-center justify-center flex-1 px-3 py-5">
              <Paragraph _className="text-red-600 text-lg text-center leading-6" weight="bold">
                Losing{"\n"}Rolls
              </Paragraph>
              <Heading _className="text-red-600 text-3xl text-center" weight="bold">
                {getLosingRolls(game)}
              </Heading>
            </View>
          </View>
        </View>
        <Button variant="solid" onPress={props.onAcknowledge}>
          ACKNOWLEDGE
        </Button>
      </View>
    </View>
  );
};
