import React, { useState } from "react";
import { TouchableOpacity, View } from "react-native";

import { Button } from "../../core/components/button";
import { Heading } from "../../core/components/heading";
import { Paragraph } from "../../core/components/paragraph";
import { useCurrentPlayer } from "../../core/hooks/use-current-player";
import { ProfileCard } from "../../profile/components/profile-card";

export interface BetFormState {
  betAmount: number;
  betType: "PASS" | "CRAP";
  increment: number;
}

export const initialBetFormState: BetFormState = {
  betAmount: 0,
  betType: "PASS",
  increment: 10,
};

export interface BetFormProps {
  isLoading: boolean;
  onPlaceBet: (values: BetFormState) => void;
}

export const BetForm = (props: BetFormProps) => {
  const { player: profile } = useCurrentPlayer();

  const [formState, setFormState] = useState<BetFormState>(initialBetFormState);

  return (
    <View className="space-y-4">
      {profile && (
        <ProfileCard balance={profile.bankroll?.balance || 0} name={profile.name} hideAvatar />
      )}
      <View className="flex items-center justify-center p-5 space-y-4 bg-yellow-600 rounded-xl">
        <Heading weight="bold" _className="text-2xl">
          Bet
        </Heading>
        <View className="flex flex-row items-center">
          <Button
            _className="flex flex-1"
            {...(formState.betType === "PASS"
              ? { variant: "solid" }
              : { variant: "ghost", _paragraph: { _className: "text-blue-900" } })}
            onPress={() => setFormState((p) => ({ ...p, betType: "PASS" }))}>
            PASS
          </Button>
          <Button
            _className="flex flex-1"
            {...(formState.betType === "CRAP"
              ? { variant: "solid" }
              : { variant: "ghost", _paragraph: { _className: "text-blue-900" } })}
            onPress={() => setFormState((p) => ({ ...p, betType: "CRAP" }))}>
            CRAP
          </Button>
        </View>
        <View className="flex self-stretch overflow-hidden rounded-lg">
          <View className="flex flex-row items-center justify-between p-5 bg-yellow-700 lg:py-2">
            <TouchableOpacity
              className="flex items-center justify-center w-10 bg-gray-500 rounded-full aspect-square"
              disabled={formState.betAmount <= 0}
              onPress={() =>
                setFormState((p) => {
                  const newBetAmount = p.betAmount - p.increment;
                  const valid = newBetAmount >= 0;
                  return valid ? { ...p, betAmount: newBetAmount } : p;
                })
              }>
              <Paragraph _className="text-3xl">-</Paragraph>
            </TouchableOpacity>
            <View className="flex-row items-center space-x-1">
              <Heading weight="bold" _className="text-3xl">
                {formState.betAmount}
              </Heading>
              <Paragraph _className="text-lg">ZBK</Paragraph>
            </View>
            <TouchableOpacity
              className="flex items-center justify-center w-10 bg-gray-500 rounded-full aspect-square"
              onPress={() =>
                setFormState((p) => {
                  const newBetAmount = p.betAmount + p.increment;
                  const valid = newBetAmount <= (profile?.bankroll?.balance || 0);
                  return valid ? { ...p, betAmount: newBetAmount } : p;
                })
              }>
              <Paragraph _className="text-3xl">+</Paragraph>
            </TouchableOpacity>
          </View>
          <View className="flex flex-row items-center p-5 bg-gray-500 lg:py-2">
            <View className="flex flex-1">
              <Paragraph _className="whitespace-nowrap">Increment by</Paragraph>
            </View>
            <View className="flex flex-row flex-1 space-x-2">
              <Button
                size="sm"
                variant="ghost"
                {...(formState.increment === 10 && {
                  variant: "solid",
                  _className: "bg-blue-900",
                })}
                onPress={() => {
                  setFormState((p) => ({ ...p, increment: 10 }));
                }}>
                10s
              </Button>
              <Button
                size="sm"
                variant="ghost"
                {...(formState.increment === 100 && {
                  variant: "solid",
                  _className: "bg-blue-900",
                })}
                onPress={() => {
                  setFormState((p) => ({ ...p, increment: 100 }));
                }}>
                100s
              </Button>
            </View>
          </View>
        </View>
        <Button
          variant="solid"
          _className="flex self-stretch"
          onPress={() => props.onPlaceBet(formState)}
          isLoading={props.isLoading}
          disabled={props.isLoading || formState.betAmount <= 0}>
          Place Bet
        </Button>
      </View>
    </View>
  );
};
