import { GameDto } from "../../models/game";
import { TalkTrash, TrashGameState, TrashSeverity } from "../../services/trash-talk-service";

const PASS_NUMBERS = "7 & 11";
const CRAP_NUMBERS = "2, 3, & 12";
const NOT_THE_POINT = "7";

const DRAW_MESSAGE = "KEEP_GOING!";
const WIN_MESSAGE = "YOU WIN!!!";
const LOSE_MESSAGE = "YOU LOSE!";

export function getWinningRolls(game: GameDto | null) {
  if (!game) return null;
  if (game.thePoint > 0 && (game.rolls?.length || 0) > 1) {
    return game.betType === "PASS" ? game.thePoint.toString() : NOT_THE_POINT;
  } else {
    return game.betType === "PASS" ? PASS_NUMBERS : CRAP_NUMBERS;
  }
}

export function getLosingRolls(game: GameDto | null) {
  if (!game) return null;
  if (game.thePoint > 0 && (game.rolls?.length || 0) > 1) {
    return game.betType === "PASS" ? NOT_THE_POINT : game.thePoint.toString();
  } else {
    return game.betType === "PASS" ? CRAP_NUMBERS : PASS_NUMBERS;
  }
}

export function getLatestRoll(game: GameDto | null) {
  if (!game) return null;
  return game.rolls?.[game.rolls?.length - 1];
}

export function getLatestRollTotal(game: GameDto | null) {
  if (!game) return null;
  const roll = getLatestRoll(game);
  return (roll?.dice1 || 0) + (roll?.dice2 || 0);
}

export function getOutcomeMessage(game: GameDto | null) {
  if (!game) return null;
  if (game.gameOver) {
    if (game.shooterWins) {
      return WIN_MESSAGE;
    }
    return LOSE_MESSAGE;
  }
  return DRAW_MESSAGE;
}

export function getTrashTalk(game: GameDto | null) {
  if (!game) return null;
  if (game.gameOver) {
    if (game.shooterWins) {
      return TalkTrash(TrashGameState.Win, TrashSeverity.Mild);
    }
    return TalkTrash(TrashGameState.Lose, TrashSeverity.Mild);
  }
  return TalkTrash(TrashGameState.Draw, TrashSeverity.Mild);
}
