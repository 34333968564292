import { Ionicons } from "@expo/vector-icons";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";

import { HomeStackNavigator } from "./home-stack.navigator";
import { ProfileStackNavigator } from "./profile-stack.navigator";
import { icons } from "../../../assets/icons";
import { BuildContainer } from "../../modules/build/build.container";
import { BuildScreen } from "../../modules/build/build.screen";
import { ChatContainer } from "../../modules/chat/chat.container";
import { ChatScreen } from "../../modules/chat/chat.screen";
import { LeadersContainer } from "../../modules/leaders/leaders.container";
import { LeadersScreen } from "../../modules/leaders/leaders.screen";
import { HomeTabBar } from "../components/HomeTabBar";
import { Routes } from "../routes";

const Leaders = LeadersContainer(LeadersScreen);
const Build = BuildContainer(BuildScreen);
const Home = HomeStackNavigator;
const Chat = ChatContainer(ChatScreen);
const Profile = ProfileStackNavigator;

export type HomeTabParamList = {
  [Routes.HOME_TAB__HOME]: undefined;
  [Routes.HOME_TAB__BUILD]: undefined;
  [Routes.HOME_TAB__CHAT]: undefined;
  [Routes.HOME_TAB__LEADERS]: undefined;
  [Routes.HOME_TAB__PROFILE]: undefined;
};

const Tab = createBottomTabNavigator<HomeTabParamList>();

export const HomeTabNavigator = () => {
  return (
    <Tab.Navigator
      initialRouteName={Routes.HOME_TAB__HOME}
      screenOptions={{ headerShown: false }}
      tabBar={(props) => <HomeTabBar {...props} />}>
      <Tab.Screen
        name={Routes.HOME_TAB__HOME}
        component={Home}
        options={{
          tabBarIcon: ({ color, size }) => (
            <icons.HomeIcon color={color} width={size} height={size} />
          ),
        }}
      />
      {/* <Tab.Screen
        name={Routes.HOME_TAB__BUILD}
        component={Build}
        options={{
          tabBarIcon: ({ color, size }) => (
            <Ionicons name="hammer-outline" size={size} color={color} />
          ),
        }}
      /> */}
      <Tab.Screen
        name={Routes.HOME_TAB__CHAT}
        component={Chat}
        options={{
          tabBarIcon: ({ color, size }) => (
            <icons.ChatIcon color={color} width={size} height={size} />
          ),
        }}
      />
      <Tab.Screen
        name={Routes.HOME_TAB__LEADERS}
        component={Leaders}
        options={{
          tabBarIcon: ({ color, size }) => (
            <icons.TrophyIcon color={color} width={size} height={size} />
          ),
        }}
      />
      <Tab.Screen
        name={Routes.HOME_TAB__PROFILE}
        component={Profile}
        options={{
          tabBarIcon: ({ color, size }) => (
            <Ionicons name="ios-person-outline" size={size} color={color} />
          ),
        }}
      />
    </Tab.Navigator>
  );
};
