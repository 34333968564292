import { LinearGradient } from "expo-linear-gradient";
import React from "react";
import { View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { ProfileCard } from "./components/profile-card";
import { Player } from "../../models/player";
import { colors } from "../../tw-config";
import { Button } from "../core/components/button";

export interface ProfileScreenProps {
  player: Player | null;
  onEditProfile: () => void;
  onSignOut: () => void;
}

export const ProfileScreen = (props: ProfileScreenProps) => {
  const { top, bottom } = useSafeAreaInsets();

  return (
    <>
      <LinearGradient
        colors={[colors.oppsGold, colors.oppsRed, colors.oppsBlack]}
        className="absolute w-full h-full"
      />
      <View
        className="flex flex-1 p-5 space-y-5 lg:p-40"
        style={{ marginTop: top, marginBottom: bottom }}>
        {props.player && (
          <ProfileCard
            name={props.player.name}
            avatar={props.player.avatar}
            balance={props.player.bankroll?.balance}
            actions={[
              <Button key="sign-out" variant="link" size="sm" onPress={props.onEditProfile}>
                Edit profile
              </Button>,
            ]}
          />
        )}
        <View className="flex items-center justify-end flex-1">
          <Button variant="link" size="sm" onPress={props.onSignOut}>
            Sign out
          </Button>
        </View>
      </View>
    </>
  );
};
