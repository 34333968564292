import resolveConfig from "tailwindcss/resolveConfig";

import tailwindConfig from "../tailwind.config.js";

export const { theme } = resolveConfig(tailwindConfig);

type DlColor = "oppsRed" | "oppsGold" | "oppsBlack";

// TODO is there a way to generate this from the config?
export const colors = theme?.colors as any;
