import clsx from "clsx";
import React, { ReactNode } from "react";
import { ActivityIndicator, TouchableOpacity, View } from "react-native";

import { Paragraph, ParagraphProps } from "./paragraph";
import { colors } from "../../../tw-config";
import { TouchableOpacityPropsCls, ViewPropsCls } from "../types/class-name";

type Size = "xs" | "sm" | "lg" | "md";

type Variant = "solid" | "outline" | "ghost" | "link";

// Container styles
const containerSizeStyles: { [size in Size]: string } = {
  xs: "h-8",
  sm: "h-10",
  md: "h-12",
  lg: "h-16",
};
const containerVariantStyles: { [variant in Variant]: string } = {
  solid: "bg-oppsRed",
  outline: "",
  ghost: "",
  link: "",
};

// Text styles
const textSizeStyles: { [size in Size]: string } = {
  xs: "text-md tracking-tight",
  sm: "text-lg tracking-tight",
  md: "text-xl tracking-tight",
  lg: "text-2xl tracking-tight",
};
const textVariantStyles: { [variant in Variant]: string } = {
  solid: "font-spaceGrotesk_700",
  outline: "font-spaceGrotesk_700",
  ghost: "font-spaceGrotesk_700",
  link: "font-spaceGrotesk_700 underline text-blue-700",
};

export interface ButtonProps extends TouchableOpacityPropsCls {
  variant: Variant;
  size?: Size;
  isLoading?: boolean;
  icon?: ReactNode;
  _paragraph?: ParagraphProps;
  _paragraphContainer?: ViewPropsCls;
}

export const Button = (props: ButtonProps) => {
  const {
    size = "md",
    variant,
    children,
    isLoading,
    icon,
    _paragraph,
    _className,
    _paragraphContainer,
    ...touchableProps
  } = props;

  const containerClasses = clsx([
    "flex items-center justify-center rounded-lg px-4",
    { "opacity-50": props.disabled },
    containerSizeStyles[size],
    containerVariantStyles[variant],
    _className,
  ]);

  const { _className: _pClassName, ...restParagraphProps } = _paragraph || {};
  const textClasses = clsx([
    "flex items-center justify-center",
    textSizeStyles[size],
    textVariantStyles[variant],
    _pClassName,
  ]);

  return (
    <TouchableOpacity {...touchableProps} className={containerClasses}>
      {isLoading ? (
        <ActivityIndicator color={colors.gray[300]} />
      ) : (
        <View
          {..._paragraphContainer}
          className={clsx("flex flex-row items-center space-x-2", _paragraphContainer?._className)}>
          {icon}
          <Paragraph {...restParagraphProps} _className={textClasses}>
            {children}
          </Paragraph>
        </View>
      )}
    </TouchableOpacity>
  );
};
