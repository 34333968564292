import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { GameDto } from "../../models/game";
import { RootState } from "../../store/store";

type GameSliceState = {
  current: GameDto | null;
};

const initialState: GameSliceState = {
  current: null,
};

export const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {
    // Model reducers as events, not setters
    currentGameAdded(state, action: PayloadAction<GameDto>) {
      state.current = action.payload;
    },
    currentGameRemoved(state) {
      state.current = null;
    },
  },
});

export const { currentGameAdded, currentGameRemoved } = gameSlice.actions;

// Define selectors alongside reducers
// Selectors should be named as "selectThing"
export const selectCurrentGame = (state: RootState) => state.game.current;
