import auth from "@react-native-firebase/auth";
import { StackScreenProps } from "@react-navigation/stack";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DEFAULT_ROLL_DURATION } from "./components/die-roll";
import { GameScreenProps } from "./game.screen";
import { currentGameAdded, selectCurrentGame } from "./game.slice";
import { GameDto } from "../../models/game";
import { HomeStackParamList } from "../../navigation/navigators/home-stack.navigator";
import { Routes } from "../../navigation/routes";
import { useGamesControllerShootMutation } from "../../services/api/api-service";

type ContainerProps = StackScreenProps<HomeStackParamList, Routes.HOME_STACK__GAME>;

export const GameContainer = (Screen: React.FC<GameScreenProps>) =>
  function _(props: ContainerProps) {
    const dispatch = useDispatch();

    const userId = auth().currentUser?.uid;
    const game = useSelector(selectCurrentGame);

    const [shoot, { isLoading }] = useGamesControllerShootMutation();

    const [diceRolling, setDiceRolling] = useState(false);

    useEffect(() => {
      const ROLL_RESULT_VIEWING_DURATION = 3000;

      let timeout: NodeJS.Timeout;
      if (diceRolling) {
        timeout = setTimeout(() => {
          setDiceRolling(false);
        }, DEFAULT_ROLL_DURATION + ROLL_RESULT_VIEWING_DURATION);
      }

      return () => {
        clearTimeout(timeout);
      };
    }, [diceRolling]);

    if (!game) return null;
    return (
      <Screen
        diceRolling={diceRolling}
        isLoading={isLoading}
        game={game}
        onShoot={async () => {
          if (!userId) return;
          const result = await shoot({ playerId: userId }).unwrap();
          const game = result as GameDto;
          dispatch(currentGameAdded(game));
          setDiceRolling(true);
        }}
        onNext={() => props.navigation.navigate(Routes.HOME_STACK__HOME)}
      />
    );
  };
