import React, { ReactNode } from "react";
import { Image, View } from "react-native";

import { Paragraph } from "../../core/components/paragraph";
import { SafeImage } from "../../core/components/safe-image";

export interface LeaderCardProps {
  rank: number;
  imageUri: string;
  name: string;
  points: ReactNode;
}

export const LeaderCard = (props: LeaderCardProps) => {
  return (
    <View className="flex flex-row overflow-hidden rounded-xl">
      <View className="flex justify-center basis-1/5 bg-oppsGold">
        <Paragraph _className="text-center">{props.rank}</Paragraph>
      </View>
      <View className="flex basis-1/5">
        <SafeImage
          fallback="avatar"
          className="w-full aspect-square"
          source={{ uri: props.imageUri }}
        />
      </View>
      <View className="justify-center pl-2 bg-red-900 basis-3/5">
        <Paragraph _className="whitespace-nowrap">{props.name}</Paragraph>
        <Paragraph>{props.points}</Paragraph>
      </View>
    </View>
  );
};
