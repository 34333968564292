import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ChatMessage } from "./chat.type";
import { RootState } from "../../store/store";

const chatAdapter = createEntityAdapter<ChatMessage>({
  // sortComparer: (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime(),
  sortComparer: (a, b) => a.timestamp - b.timestamp,
});

const initialState = chatAdapter.getInitialState();

export const chatSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // Model actions as events, not setters
    // https://redux.js.org/style-guide/#model-actions-as-events-not-setters
    chatMessagesReceived(state, action: PayloadAction<{ chatMessages: ChatMessage[] }>) {
      chatAdapter.setAll(state, action.payload.chatMessages);
    },
  },
});

export const { chatMessagesReceived } = chatSlice.actions;

// Define selectors alongside reducers:
// https://redux.js.org/usage/deriving-data-selectors#define-selectors-alongside-reducers
// Name selector functions as "selectThing"
// https://redux.js.org/style-guide/#name-selector-functions-as-selectthing

export const chatSelectors = chatAdapter.getSelectors<RootState>((state) => state.chat);

export const { selectAll: selectChatMessages, selectById: selectChatMessageById } = chatSelectors;
