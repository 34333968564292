export const drawTrashTalks = [
  {
    id: "9d2a2b15-14c5-49e1-9b1a-b06f647329c8",
    message: "Don't let me smoke you, lil folk. You lucky we out here shootin dice and not blicks.",
    imageMobile: require("../../../assets/images/0pps-individual-rearview.png"),
    imageDesktop: require("../../../assets/images/0pps-individual-rearview-desktop.png"),
    severity: 1,
    weight: 1,
  },
  {
    id: "30d2b6fb-9200-483d-8c92-dc4503a83351",
    message: "Bout to line you up.",
    imageMobile: require("../../../assets/images/0pps-individual-fullbody.png"),
    imageDesktop: require("../../../assets/images/0pps-individual-fullbody-desktop.png"),
    severity: 1,
    weight: 1,
  },
  {
    id: "4ff3e551-38dd-45a0-8d5d-055139b50e69",
    message: "I think your moms owe me, shorty",
    imageMobile: require("../../../assets/images/0pps-individual-rearview.png"),
    imageDesktop: require("../../../assets/images/0pps-individual-rearview-desktop.png"),
    severity: 1,
    weight: 1,
  },
  {
    id: "0c99bf9f-936d-4f1f-8f66-e6882b3ac2c2",
    message: "Bout to be a major lick!",
    imageMobile: require("../../../assets/images/0pps-individual-rearview.png"),
    imageDesktop: require("../../../assets/images/0pps-individual-rearview-desktop.png"),
    severity: 1,
    weight: 1,
  },
  {
    id: "53ee3d87-19c3-4463-95f1-6d94c432c22e",
    message: "Somebody gon' get packed up!!",
    imageMobile: require("../../../assets/images/0pps-individual-rearview.png"),
    imageDesktop: require("../../../assets/images/0pps-individual-rearview-desktop.png"),
    severity: 1,
    weight: 1,
  },
  {
    id: "9a1f3fe4-4b69-40d1-9c19-0b1667d0df9f",
    message: "You gon gimme my money, folk.",
    imageMobile: require("../../../assets/images/0pps-individual-sideview.png"),
    imageDesktop: require("../../../assets/images/0pps-individual-sideview-desktop.png"),
    severity: 1,
    weight: 1,
  },
];
