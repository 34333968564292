import { LinearGradient } from "expo-linear-gradient";
import React, { Dispatch, SetStateAction } from "react";
import { KeyboardAvoidingView, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { colors } from "../../tw-config";
import { Button } from "../core/components/button";
import { Heading } from "../core/components/heading";
import { Paragraph } from "../core/components/paragraph";
import { TextField } from "../core/components/text-field";

export interface ForgotPasswordFormState {
  email: string;
}

export interface ForgotPasswordScreenProps {
  formState: ForgotPasswordFormState;
  onChangeFormState: Dispatch<SetStateAction<ForgotPasswordFormState>>;
  isSendingResetPasswordEmail: boolean;
  isResetPasswordEmailSent: boolean;
  onSendResetPasswordEmail: () => void;
  onBackToSignIn: () => void;
  title: string;
}

export const ForgotPasswordScreen = (props: ForgotPasswordScreenProps) => {
  const { top, bottom } = useSafeAreaInsets();

  const renderConfirmation = () => {
    return (
      <View className="flex w-full space-y-8 md:min-w-max md:max-w-md">
        <View className="space-y-2">
          <Heading weight="bold" _className="text-3xl">
            Email Sent
          </Heading>
          <Paragraph>Check your email and open the link we sent to continue</Paragraph>
          <View className="flex flex-row justify-end">
            <Button
              variant="link"
              size="xs"
              onPress={props.onBackToSignIn}
              disabled={props.isSendingResetPasswordEmail}>
              Go back to Sign in
            </Button>
          </View>
        </View>
      </View>
    );
  };

  const renderForm = () => {
    return (
      <View className="flex w-full space-y-8 md:min-w-max md:max-w-md">
        <View className="space-y-2">
          <Heading weight="bold" _className="text-3xl">
            {props.title}
          </Heading>
          <Paragraph>
            Enter your email address and we'll send you a link to reset your password.
          </Paragraph>
        </View>
        <View className="p-5 space-y-4 bg-oppsBlack rounded-3xl">
          <TextField
            label="Email"
            keyboardType="email-address"
            value={props.formState.email}
            onChangeText={(email) =>
              props.onChangeFormState((prev) => ({
                ...prev,
                email,
              }))
            }
          />
          <Button
            variant="solid"
            onPress={props.onSendResetPasswordEmail}
            isLoading={props.isSendingResetPasswordEmail}
            disabled={props.isSendingResetPasswordEmail}>
            Request Reset Link
          </Button>
          <View className="flex flex-row justify-end">
            <Button
              variant="link"
              size="xs"
              onPress={props.onBackToSignIn}
              disabled={props.isSendingResetPasswordEmail}>
              Go back
            </Button>
          </View>
        </View>
      </View>
    );
  };

  return (
    <>
      <LinearGradient
        colors={[colors.oppsGold, colors.oppsRed, colors.oppsBlack]}
        className="absolute w-full h-full"
      />
      <KeyboardAvoidingView
        behavior="height"
        className="flex items-center justify-center flex-1 p-5 lg:p-40"
        style={{ marginTop: top, marginBottom: bottom }}>
        {props.isResetPasswordEmailSent ? renderConfirmation() : renderForm()}
      </KeyboardAvoidingView>
    </>
  );
};
