import { Ionicons } from "@expo/vector-icons";
import Constants from "expo-constants";
import { useEffect, useRef } from "react";
import type { Toast as HotToast } from "react-hot-toast/headless";
import { Animated, Text, View } from "react-native";

import { isRenderable } from "../utils/type-check.util";

function getIcon(type: HotToast["type"]) {
  switch (type) {
    case "success":
      return <Ionicons name="checkmark-circle" size={24} color="green" />;
    case "error":
      return <Ionicons name="close-circle" size={24} color="red" />;
    default:
      break;
  }
}

export interface ToastProps {
  t: HotToast;
  updateHeight: (height: number) => void;
  offset: number;
}

/**
 * @see https://github.com/timolins/react-hot-toast/issues/2
 */
export const Toast = ({ t, updateHeight, offset }: ToastProps) => {
  // Animations for enter and exit
  const fadeAnim = useRef(new Animated.Value(0.5)).current;
  const posAnim = useRef(new Animated.Value(-80)).current;

  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: t.visible ? 1 : 0,
      duration: 300,
      useNativeDriver: false,
    }).start();
  }, [fadeAnim, t.visible]);

  useEffect(() => {
    Animated.spring(posAnim, {
      toValue: t.visible ? offset : -80,
      useNativeDriver: false,
    }).start();
  }, [posAnim, offset, t.visible]);

  return (
    <Animated.View
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        zIndex: t.visible ? 9999 : undefined,
        alignItems: "center",
        opacity: fadeAnim,
        transform: [{ translateY: posAnim }],
      }}>
      <View
        onLayout={(event) => updateHeight(event.nativeEvent.layout.height)}
        style={{
          margin: Constants.statusBarHeight + 10,
          backgroundColor: "#000",
          borderRadius: 30,
          flexDirection: "row",
          alignItems: "center",
          paddingVertical: 8,
          paddingHorizontal: 12,
        }}
        key={t.id}>
        {getIcon(t.type) || <Text>{t.icon} </Text>}
        <Text style={{ color: "#fff", padding: 4, flex: 1, textAlign: "center" }}>
          {isRenderable(t.message) && t.message}
        </Text>
      </View>
    </Animated.View>
  );
};
