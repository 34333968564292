import React from "react";
import { Text, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { env } from "../../env";

export interface BuildScreenProps {}

export const BuildScreen = (props: BuildScreenProps) => {
  const { top, bottom } = useSafeAreaInsets();

  return (
    <View
      className="flex-1 p-5 space-y-5 bg-red-100"
      style={{ marginTop: top, marginBottom: bottom }}>
      <Text>Build</Text>
      <Text>{JSON.stringify(env, null, 2)}</Text>
    </View>
  );
};
