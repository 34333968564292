import auth from "@react-native-firebase/auth";
import { useFocusEffect } from "@react-navigation/native";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { dbService } from "../../../services/db-service";
import { profileAdded, selectProfile } from "../../profile/profile.slice";

export const useCurrentPlayer = () => {
  const dispatch = useDispatch();

  const player = useSelector(selectProfile);
  const [isLoading, setIsLoading] = useState(false);

  useFocusEffect(
    useCallback(() => {
      const userId = auth().currentUser?.uid;
      if (!userId) return;
      setIsLoading(true);
      const { path, onValueChange } = dbService.getPlayer(userId, {
        onSuccess: (player) => {
          setIsLoading(false);
          dispatch(profileAdded(player));
        },
        onFailure() {
          setIsLoading(false);
        },
      }); // Stop listening for updates when no longer required
      return () => dbService.cleanupValueEvent(path, onValueChange);
    }, [dispatch]),
  );

  return { player, isLoading };
};
