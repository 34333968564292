import clsx from "clsx";
import React from "react";
import { TextInput, View } from "react-native";

import { Paragraph } from "./paragraph";
import { colors } from "../../../tw-config";
import { TextInputPropsCls } from "../types/class-name";

export interface TextFieldProps extends TextInputPropsCls {
  label?: string;
  variant?: "outlined" | "unstyled";
  _containerClassName?: string;
}

export const TextField = (props: TextFieldProps) => {
  const {
    variant = "outlined",
    label,
    style,
    _className,
    _containerClassName,
    ...textInputProps
  } = props;

  return (
    <View className={clsx("space-y-1", _containerClassName)} style={style}>
      {Boolean(label) && <Paragraph _className="text-gray-300">{label}</Paragraph>}
      <TextInput
        cursorColor="white"
        {...textInputProps}
        placeholderTextColor={colors.gray[500]}
        className={clsx(
          "p-2 text-white rounded-md",
          {
            "border border-gray-300": variant === "outlined",
          },
          _className,
        )}
        returnKeyType="none"
      />
    </View>
  );
};
