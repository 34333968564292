import clsx from "clsx";
import React from "react";
import { ImageSourcePropType, View } from "react-native";

import { Heading } from "../core/components/heading";
import { Paragraph } from "../core/components/paragraph";
import { SafeImage } from "../core/components/safe-image";
import { ViewPropsCls } from "../core/types/class-name";

export interface ChatBubbleProps extends ViewPropsCls {
  type: "user" | "player";
  avatar?: ImageSourcePropType;
  name?: string;
  message?: string;
}

export const ChatBubble = ({
  type,
  avatar,
  name,
  message,
  _className,
  ...restProps
}: ChatBubbleProps) => {
  return (
    <View {...restProps} className={clsx("flex flex-row items-start space-x-3", _className)}>
      {type === "player" && avatar && (
        <SafeImage
          fallback="avatar"
          className="w-12 h-12 rounded-full aspect-square"
          source={avatar}
        />
      )}
      <View className="items-start flex-1 space-y-1">
        <View
          className={clsx("p-3", {
            "bg-yellow-600 rounded-r-xl rounded-bl-xl": type === "player",
            "self-end bg-gray-400/25 rounded-l-xl rounded-br-xl": type === "user",
          })}>
          {type === "player" && <Heading _className="text-red-900">{name}</Heading>}
          <Paragraph
            _className={clsx("leading-[18px] tracking-tighter", {
              "text-blue-900": type === "player",
            })}>
            {message}
          </Paragraph>
        </View>
      </View>
    </View>
  );
};
