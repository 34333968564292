import clsx from "clsx";
import { LinearGradient } from "expo-linear-gradient";
import React from "react";
import { ImageBackground, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { StatsRow } from "./components/stats-row";
import { images } from "../../../assets/images";
import { Player } from "../../models/player";
import { colors } from "../../tw-config";
import { Button } from "../core/components/button";
import { Heading } from "../core/components/heading";
import { formatCurrency } from "../core/utils/formatter.util";
import { isWeb } from "../core/utils/platform.util";
import { ProfileCard } from "../profile/components/profile-card";

export type HomeScreenProps = {
  version: string;
  player: Player | null;
  isLoading: boolean;
  onSignOut: () => void;
  onPlay: () => void;
};

export const HomeScreen = (props: HomeScreenProps) => {
  const { top, bottom } = useSafeAreaInsets();

  return (
    <>
      <LinearGradient
        colors={[colors.oppsGold, colors.oppsRed, colors.oppsBlack]}
        className="absolute w-full h-full"
      />
      <View
        className="flex-1 p-5 space-y-5 lg:p-40"
        style={{ marginTop: top, marginBottom: bottom }}>
        {props.player && (
          <ProfileCard
            name={props.player.name}
            avatar={props.player.avatar}
            balance={props.player.bankroll?.balance || 0}
          />
        )}
        <View className={clsx({ "flex flex-col lg:flex-row": isWeb })}>
          <View className="items-center p-5 space-y-1 rounded-3xl bg-oppsBlack lg:flex lg:flex-1 lg:h-full lg:p-10">
            <Heading weight="bold" _className="text-xl text-oppsRed">
              Player Stats
            </Heading>
            <StatsRow title="Activity" value={props.player?.gamesCount} label="Games" />
            {(props.player?.bankroll?.totalWon || 0) >= (props.player?.bankroll?.totalLost || 0) ? (
              <StatsRow
                title="Up"
                value={formatCurrency(props.player?.bankroll?.net)}
                label="ZBK"
              />
            ) : (
              <StatsRow
                title="Down"
                value={formatCurrency(props.player?.bankroll?.net)}
                label="ZBK"
                highlight
              />
            )}
            <StatsRow
              title="Won"
              value={formatCurrency(props.player?.bankroll?.totalWon)}
              label="ZBK"
            />
            <StatsRow
              title="Lost"
              value={formatCurrency(props.player?.bankroll?.totalLost)}
              label="ZBK"
            />
          </View>
          <View className="w-5 h-5" />
          <View className="overflow-hidden rounded-lg lg:flex lg:flex-1 lg:h-full lg:rounded-3xl">
            <ImageBackground
              source={images.startPlayBanner}
              className="lg:flex lg:h-full lg:p-10 lg:items-end lg:justify-end"
              resizeMode="cover">
              <Button
                variant="solid"
                size="lg"
                _className="lg:w-1/3"
                onPress={props.onPlay}
                isLoading={props.isLoading}
                disabled={props.isLoading}>
                PLAY!
              </Button>
            </ImageBackground>
          </View>
        </View>
      </View>
    </>
  );
};
