import { drawTrashTalks } from "./trashtalk/draw-trash-talks";
import { loseTrashTalks } from "./trashtalk/lose-trash-talks";
import { rollTrashTalks } from "./trashtalk/roll-trash-talks";
import { winTrashTalks } from "./trashtalk/win-trash-talks";

export enum TrashSeverity {
  Mild = 1,
  Harsh,
  Brutal,
}

export enum TrashGameState {
  Roll,
  Win,
  Lose,
  Draw,
}

export interface ITrashTalk {
  message: string;
  imageMobile: string;
  imageDesktop: string;
  severity: TrashSeverity;
  weight: number;
}

export function TalkTrash(state: TrashGameState, severity: TrashSeverity): ITrashTalk {
  let trashTalk: ITrashTalk;

  switch (state) {
    case TrashGameState.Roll:
      trashTalk = getRandomTrashTalk(rollTrashTalks, severity);
      break;
    case TrashGameState.Win:
      trashTalk = getRandomTrashTalk(winTrashTalks, severity);
      break;
    case TrashGameState.Lose:
      trashTalk = getRandomTrashTalk(loseTrashTalks, severity);
      break;
    case TrashGameState.Draw:
    default:
      trashTalk = getRandomTrashTalk(drawTrashTalks, severity);
      break;
  }

  return trashTalk;
}

const getRandomTrashTalk = (messages: ITrashTalk[], severity: TrashSeverity): ITrashTalk => {
  // Protocol
  //  1. Filter based on severity
  //  2. Pluck a random message from what remains

  // Filter trash
  const filteredTrash: ITrashTalk[] = messages.filter((t) => t.severity === severity);

  // Calculate total weight
  let total = 0;
  filteredTrash.forEach((t) => (total += t.weight));

  // Get the random floor
  const threshold = Math.random() * total;

  // Get the TrashTalk identified by the floor and return it
  total = 0;
  let selectedTrash = filteredTrash[filteredTrash.length - 1];

  filteredTrash.every((t) => {
    total += t.weight;
    if (total >= threshold) {
      selectedTrash = t;
      return false;
    }
    return true;
  });

  return selectedTrash;
};
