import storage from "@react-native-firebase/storage";

/**
 * A service for managing upload flows.
 */
class UploadService {
  private static instance: UploadService;
  private static avatarsPath = "dicelord/avatars";

  /**
   * The UploadService's constructor should always be private to prevent direct
   * construction calls with the `new` operator.
   */
  private constructor() {}

  /**
   * The static method that controls the access to the singleton instance.
   *
   * This implementation let you subclass the UploadService class while keeping
   * just one instance of each subclass around.
   */
  public static getInstance(): UploadService {
    if (!UploadService.instance) {
      UploadService.instance = new UploadService();
    }

    return UploadService.instance;
  }

  /**
   *@param uri URI to the local image or video file
   */
  public async uploadUri(id: string, uri: string) {
    const response = await fetch(uri);
    const blob = await response.blob();

    const avatarPath = `${UploadService.avatarsPath}/${id}`;
    const storageRef = storage().ref(avatarPath);
    await storageRef.put(blob);
    const url = await storageRef.getDownloadURL();

    return { url, id };
  }

  public async getDownloadUrl(id: string) {
    const avatarPath = `${UploadService.avatarsPath}/${id}`;
    const storageRef = storage().ref(avatarPath);
    const url = await storageRef.getDownloadURL();
    return url;
  }
}

export const uploadService = UploadService.getInstance();
