import React from "react";
import { View } from "react-native";

import { GameDto } from "../../../models/game";
import { Heading } from "../../core/components/heading";
import { Paragraph } from "../../core/components/paragraph";
import { getWinningRolls, getLosingRolls } from "../game.utils";

export interface ScoreboardProps {
  game: GameDto;
}

export const Scoreboard = (props: ScoreboardProps) => {
  return (
    <View className="flex flex-row p-5 bg-black rounded-xl">
      <View className="flex bg-yellow-600 basis-1/4 rounded-l-md">
        <Paragraph _className="text-center text-oppsBlack bg-yellow-700 p-1" weight="bold">
          WIN
        </Paragraph>
        <Heading
          _className="text-center text-oppsBlack p-1 text-lg"
          weight="bold"
          numberOfLines={1}
          adjustsFontSizeToFit>
          {getWinningRolls(props.game)}
        </Heading>
      </View>
      <View className="flex-1 bg-black border-b-2 border-yellow-600">
        <Paragraph
          _className="text-center text-oppsBlack bg-yellow-700 border-2 border-yellow-600 p-0.5"
          weight="bold">
          BET
        </Paragraph>
        <View className="flex flex-row items-end justify-center p-1 space-x-1">
          <Heading _className="text-center text-lg" weight="bold" numberOfLines={1}>
            {props.game.betAmount}
          </Heading>
          <Paragraph _className="text-xs pb-1">ZBK</Paragraph>
        </View>
      </View>
      <View className="flex bg-yellow-600 basis-1/4 rounded-r-md">
        <Paragraph _className="text-center text-oppsBlack bg-yellow-700 p-1" weight="bold">
          LOSE
        </Paragraph>
        <Heading
          _className="text-center text-oppsBlack p-1 text-lg"
          weight="bold"
          numberOfLines={1}
          adjustsFontSizeToFit>
          {getLosingRolls(props.game)}
        </Heading>
      </View>
    </View>
  );
};
