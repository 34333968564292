import React from "react";
import { ImageBackground, View } from "react-native";

import { BetConfirmation } from "./components/bet-confirmation";
import { BetForm, BetFormState } from "./components/bet-form";
import { images } from "../../../assets/images";
import { GameDto } from "../../models/game";
import { Background } from "../core/components/background";

export interface BetScreenProps {
  game: GameDto | null;
  isLoading: boolean;
  step: "place" | "confirm"; // Place bet, confirm bet
  onPlaceBet: (formState: BetFormState) => void;
  onAcknowledge: () => void;
}

export const BetScreen = (props: BetScreenProps) => {
  return (
    <Background _className="lg:p-28">
      <View className="flex flex-row flex-1">
        <View className="flex justify-center flex-1 lg:basis-1/3">
          {props.step === "place" && (
            // Place bet
            <BetForm isLoading={props.isLoading} onPlaceBet={props.onPlaceBet} />
          )}
          {props.step === "confirm" && props.game !== null && (
            // Confirm bet
            <BetConfirmation onAcknowledge={props.onAcknowledge} />
          )}
        </View>
        <View className="flex basis-0 lg:pl-5 lg:basis-2/3">
          <ImageBackground
            source={images.gameboardBg}
            resizeMode="cover"
            className="w-full h-full overflow-hidden rounded-3xl"
          />
        </View>
      </View>
    </Background>
  );
};
