import { StackScreenProps } from "@react-navigation/stack";
import React, { useState } from "react";

import { EditProfileFormState, EditProfileScreenProps } from "./edit-profile.screen";
import { ProfileStackParamList } from "../../navigation/navigators/profile-stack.navigator";
import { Routes } from "../../navigation/routes";
import { usePlayersControllerUpdateProfileMutation } from "../../services/api/api-service";
import { uploadService } from "../../services/upload-service";
import { useCurrentPlayer } from "../core/hooks/use-current-player";
import { dlToast } from "../core/utils/toast.util";

type ContainerProps = StackScreenProps<ProfileStackParamList, Routes.PROFILE_STACK__EDIT_PROFILE>;

export const EditProfileContainer = (Screen: React.FC<EditProfileScreenProps>) =>
  function _(props: ContainerProps) {
    const { player, isLoading } = useCurrentPlayer();

    const [updateProfile] = usePlayersControllerUpdateProfileMutation();

    const [isUpdating, setIsUpdating] = useState(false);
    const [formState, setFormState] = useState<EditProfileFormState>({
      name: player?.name || "",
      avatarUri: player?.avatar || "",
    });

    return (
      <Screen
        player={player}
        isLoading={isLoading || isUpdating}
        isFormDisabled={isLoading || isUpdating}
        formState={formState}
        onChangeFormState={setFormState}
        onUpdateProfile={async () => {
          try {
            if (!player) throw new Error("No current player");
            setIsUpdating(true);

            let imageUri = player.avatar;
            if (formState.avatarUri) {
              await uploadService.uploadUri(player.id, formState.avatarUri);
              imageUri = await uploadService.getDownloadUrl(player.id);
            }

            await updateProfile({
              playerId: player.id,
              playerProfileDto: { name: formState.name, avatar: imageUri },
            }).unwrap();
            dlToast.success("Profile updated!");
          } catch (e: any) {
            dlToast.error("Failed to update profile!", e.message);
          } finally {
            setIsUpdating(false);
          }
        }}
        onGoBack={props.navigation.goBack}
      />
    );
  };
