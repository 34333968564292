import React, { useEffect, useState } from "react";

import { Die, Face } from "./die";
import { ViewPropsCls } from "../../core/types/class-name";
import { getRandomInt } from "../../core/utils/math.util";

export const DEFAULT_ROLL_INTERVAL_MS = 100;

export const DEFAULT_ROLL_DURATION = 3000;

export interface DieRollProps extends ViewPropsCls {
  roll: Face;
  duration?: number; // in ms
}

export const DieRoll = ({ roll, duration = DEFAULT_ROLL_DURATION, ...rest }: DieRollProps) => {
  const [value, setValue] = useState<Face>(1);

  useEffect(() => {
    const TOTAL_RANDOM_FACES = duration / DEFAULT_ROLL_INTERVAL_MS;

    let shown = 0;
    const timeout = setInterval(() => {
      if (shown >= TOTAL_RANDOM_FACES) {
        setValue(roll);
        clearInterval(timeout);
      } else {
        const random = getRandomInt(1, 6);
        setValue(random as Face);
        shown++;
      }
    }, DEFAULT_ROLL_INTERVAL_MS);

    return () => {
      clearInterval(timeout);
    };
  }, [duration, roll]);

  return <Die value={value} _className="animate" {...rest} />;
};
