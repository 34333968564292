import React, { useMemo } from "react";
import { Image, View } from "react-native";
import { useSelector } from "react-redux";

import { Heading } from "../../core/components/heading";
import { Paragraph } from "../../core/components/paragraph";
import { selectCurrentGame } from "../game.slice";
import { getLatestRollTotal, getOutcomeMessage, getTrashTalk } from "../game.utils";

export interface DiceRollResultProps {}

export const DiceRollResult = (props: DiceRollResultProps) => {
  const game = useSelector(selectCurrentGame);

  const total = useMemo(() => getLatestRollTotal(game), []);
  const outcome = useMemo(() => getOutcomeMessage(game), []);
  const trashTalk = useMemo(() => getTrashTalk(game), []);

  if (!game) return null;
  return (
    <View className="items-center w-full h-full space-y-3 bg-blue-900/75">
      <View className="flex flex-row items-center justify-center p-5 pb-0 space-x-3">
        <Paragraph _className="text-lg" weight="bold">
          Shooter rolled
        </Paragraph>
        <View className="flex items-center justify-center px-2 bg-blue-200 rounded-md">
          <Heading _className="text-3xl text-blue-900" weight="bold">
            {total}
          </Heading>
        </View>
      </View>
      <View className="self-stretch px-5 space-y-2">
        <View className="items-center p-3 -mx-5 bg-yellow-600">
          <Heading weight="bold" _className="text-xl text-center">
            {outcome}
          </Heading>
        </View>
        <Paragraph weight="bold" _className="text-md text-center">
          {trashTalk?.message}
        </Paragraph>
      </View>
      <View className="flex flex-1 w-full">
        {/* @ts-expect-error */}
        <Image source={trashTalk?.imageMobile} className="w-full h-full" resizeMode="cover" />
      </View>
    </View>
  );
};
