import auth, { FirebaseAuthTypes } from "@react-native-firebase/auth";
import { createStackNavigator } from "@react-navigation/stack";
import React, { useCallback, useEffect, useState } from "react";

import { HomeTabNavigator } from "./home-tab.navigator";
import { ApiError } from "../../models/api-error";
// import { DebugEnvContainer } from "../../modules/auth/debug-env.container";
// import { DebugEnvScreen } from "../../modules/auth/debug-env.screen";
import { ForgotPasswordContainer } from "../../modules/auth/forgot-password.container";
import { ForgotPasswordScreen } from "../../modules/auth/forgot-password.screen";
import { SignInContainer } from "../../modules/auth/sign-in.container";
import { SignInScreen } from "../../modules/auth/sign-in.screen";
import { SignUpContainer } from "../../modules/auth/sign-up.container";
import { SignUpScreen } from "../../modules/auth/sign-up.screen";
import { dlToast } from "../../modules/core/utils/toast.util";
import {
  CreatePlayerDto,
  useLazyPlayersControllerFindOneQuery,
  usePlayersControllerCreateMutation,
} from "../../services/api/api-service";
import { Routes } from "../routes";

const SignIn = SignInContainer(SignInScreen);
const SignUp = SignUpContainer(SignUpScreen);
const ForgotPassword = ForgotPasswordContainer(ForgotPasswordScreen);
// const DebugEnv = DebugEnvContainer(DebugEnvScreen);

export type AppStackParamList = {
  [Routes.HOME_TAB]: undefined;
  [Routes.AUTH_STACK__SIGN_IN]: undefined;
  [Routes.AUTH_STACK__SIGN_UP]: undefined;
  [Routes.AUTH_STACK__FORGOT_PWD]: undefined;
  [Routes.AUTH_STACK__DEBUG_ENV]: undefined;
};

const Stack = createStackNavigator<AppStackParamList>();

export const AppStackNavigator = () => {
  // Set an initializing state whilst Firebase connects
  const [initializing, setInitializing] = useState(true);
  const [user, setUser] = useState<FirebaseAuthTypes.User | null>();
  const [hasPlayer, setHasPlayer] = useState(false);

  const [getPlayer] = useLazyPlayersControllerFindOneQuery();
  const [createPlayer] = usePlayersControllerCreateMutation();

  // Handle user state changes
  const onAuthStateChanged = useCallback(
    async (user: FirebaseAuthTypes.User | null) => {
      setUser(user);
      if (initializing) setInitializing(false);

      if (user) {
        // A defined user could be a result of either sign in/sign up
        // - Check if the player already exists
        // - If it exists, proceed to the dashboard
        // - If it doesn't exist, create the player, then proceed to the dashboard
        // - If create player fails, advise the user to refresh the page
        // - Upon page refresh, the user would no longer have to sign in/sign up, but this conditional will be checked.

        getPlayer({ playerId: user.uid })
          .unwrap()
          .then((player) => {
            setHasPlayer(true);
          })
          .catch((e) => {
            const defaultMessage = "Something went wrong! Please refresh the page to try again.";
            if ((e as ApiError).status === 404) {
              const createPlayerDto: CreatePlayerDto = {
                playerId: user.uid,
                name: user.displayName || "",
                avatar: user.photoURL || "",
              };
              createPlayer({ createPlayerDto })
                .then(() => {
                  setHasPlayer(true);
                })
                .catch(() => {
                  dlToast.error(defaultMessage);
                });
            } else {
              console.log(e.message || defaultMessage);
            }
          });
      }
    },
    [createPlayer, getPlayer, initializing],
  );

  useEffect(() => {
    const subscriber = auth().onAuthStateChanged(onAuthStateChanged);
    return subscriber; // unsubscribe on unmount
  }, [onAuthStateChanged]);

  const isAuthenticated = Boolean(user);

  if (initializing) return null;

  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      {isAuthenticated && hasPlayer ? (
        <Stack.Screen name={Routes.HOME_TAB} component={HomeTabNavigator} />
      ) : (
        <>
          <Stack.Screen name={Routes.AUTH_STACK__SIGN_IN} component={SignIn} />
          <Stack.Screen name={Routes.AUTH_STACK__SIGN_UP} component={SignUp} />
          <Stack.Screen name={Routes.AUTH_STACK__FORGOT_PWD} component={ForgotPassword} />
          {/* <Stack.Screen name={Routes.AUTH_STACK__DEBUG_ENV} component={DebugEnv} /> */}
        </>
      )}
    </Stack.Navigator>
  );
};
