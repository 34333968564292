import { createStackNavigator } from "@react-navigation/stack";
import React from "react";

import { EditProfileContainer } from "../../modules/profile/edit-profile.container";
import { EditProfileScreen } from "../../modules/profile/edit-profile.screen";
import { ProfileContainer } from "../../modules/profile/profile.container";
import { ProfileScreen } from "../../modules/profile/profile.screen";
import { Routes } from "../routes";

const ViewProfile = ProfileContainer(ProfileScreen);
const EditProfile = EditProfileContainer(EditProfileScreen);

export type ProfileStackParamList = {
  [Routes.PROFILE_STACK__PROFILE]: undefined;
  [Routes.PROFILE_STACK__EDIT_PROFILE]: undefined;
};

const Stack = createStackNavigator<ProfileStackParamList>();

export const ProfileStackNavigator = () => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name={Routes.PROFILE_STACK__PROFILE} component={ViewProfile} />
      <Stack.Screen name={Routes.PROFILE_STACK__EDIT_PROFILE} component={EditProfile} />
    </Stack.Navigator>
  );
};
