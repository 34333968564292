import { createStackNavigator } from "@react-navigation/stack";
import React from "react";

import { BetContainer } from "../../modules/game/bet.container";
import { BetScreen } from "../../modules/game/bet.screen";
import { GameContainer } from "../../modules/game/game.container";
import { GameScreen } from "../../modules/game/game.screen";
import { HomeContainer } from "../../modules/home/home.container";
import { HomeScreen } from "../../modules/home/home.screen";
import { Routes } from "../routes";

const Home = HomeContainer(HomeScreen);
const Bet = BetContainer(BetScreen);
const Game = GameContainer(GameScreen);

export type HomeStackParamList = {
  [Routes.HOME_STACK__HOME]: undefined;
  [Routes.HOME_STACK__BET]: undefined;
  [Routes.HOME_STACK__GAME]: undefined;
};

const Stack = createStackNavigator<HomeStackParamList>();

export const HomeStackNavigator = () => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name={Routes.HOME_STACK__HOME} component={Home} />
      <Stack.Screen name={Routes.HOME_STACK__BET} component={Bet} />
      <Stack.Screen name={Routes.HOME_STACK__GAME} component={Game} />
    </Stack.Navigator>
  );
};
