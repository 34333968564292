import database, { FirebaseDatabaseTypes } from "@react-native-firebase/database";
import { AnyAction, Dispatch } from "@reduxjs/toolkit";

import { Player } from "../models/player";
import { chatMessagesReceived } from "../modules/chat/chat.slice";
import { dlToast } from "../modules/core/utils/toast.util";
import {
  bigBankLeadersReceived,
  downBadLeadersReceived,
  mostActiveLeadersReceived,
  wayUpLeadersReceived,
} from "../modules/leaders/leaders.slice";
import {
  BigBankLeader,
  DownBadLeader,
  MostActiveLeader,
  WayUpLeader,
} from "../modules/leaders/leaders.type";

interface EndpointArgs {
  dispatch?: Dispatch<AnyAction>;
  onSuccess?: (snapshot: FirebaseDatabaseTypes.DataSnapshot) => void;
  onFailure?: (error: Error) => void;
}

/**
 * Firebase database service
 */
class DbService {
  private static instance: DbService;

  /**
   * The DbService's constructor should always be private to prevent direct
   * construction calls with the `new` operator.
   */
  private constructor() {}

  /**
   * The static method that controls the access to the singleton instance.
   *
   * This implementation let you subclass the DbService class while keeping
   * just one instance of each subclass around.
   */
  public static getInstance(): DbService {
    if (!DbService.instance) {
      DbService.instance = new DbService();
    }

    return DbService.instance;
  }

  public cleanupValueEvent(
    path: string,
    callback: (a: FirebaseDatabaseTypes.DataSnapshot, b?: string | null) => void,
  ) {
    return database().ref(path).off("value", callback);
  }

  public getPlayer(
    playerId: string,
    args?: Omit<EndpointArgs, "onSuccess"> & { onSuccess?: (player: Player) => void },
  ) {
    const path = `/players/${playerId}`;
    const onValueChange = database()
      .ref(path)
      .on(
        "value",
        (snapshot) => {
          const player: Player = snapshot.val();
          args?.onSuccess?.({ ...player, id: playerId });
        },
        (error) => {
          args?.onFailure?.(error);
          dlToast.error(error.message || "Failed to get player leaders");
        },
      );
    return { path, onValueChange };
  }

  public getWayUpLeaders(args?: EndpointArgs) {
    const path = `/leaderboard/wayUpLeaders`;
    const onValueChange = database()
      .ref(path)
      .on(
        "value",
        (snapshot) => {
          const wayUpLeaders = snapshot.val().filter((leader: WayUpLeader) => !!leader);
          args?.dispatch?.(wayUpLeadersReceived({ leaders: wayUpLeaders }));
          args?.onSuccess?.(snapshot);
        },
        (error) => {
          args?.onFailure?.(error);
          dlToast.error(error.message || "Failed to get way up leaders");
        },
      );
    return { path, onValueChange };
  }

  public getDownBadLeaders(args?: EndpointArgs) {
    const path = `/leaderboard/downBadLeaders`;
    const onValueChange = database()
      .ref(path)
      .on(
        "value",
        (snapshot) => {
          const downBadLeaders = snapshot.val().filter((leader: DownBadLeader) => !!leader);
          args?.dispatch?.(downBadLeadersReceived({ leaders: downBadLeaders }));
          args?.onSuccess?.(snapshot);
        },
        (error) => {
          args?.onFailure?.(error);
          dlToast.error(error.message || "Failed to get down bad leaders");
        },
      );
    return { path, onValueChange };
  }

  public getMostActiveLeaders(args?: EndpointArgs) {
    const path = `/leaderboard/mostActiveLeaders`;
    const onValueChange = database()
      .ref(path)
      .on(
        "value",
        (snapshot) => {
          const mostActiveLeaders = snapshot.val().filter((leader: MostActiveLeader) => !!leader);
          args?.dispatch?.(mostActiveLeadersReceived({ leaders: mostActiveLeaders }));
          args?.onSuccess?.(snapshot);
        },
        (error) => {
          args?.onFailure?.(error);
          dlToast.error(error.message || "Failed to get most active leaders");
        },
      );
    return { path, onValueChange };
  }

  public getBigBankLeaders(args?: EndpointArgs) {
    const path = `/leaderboard/bigBankLeaders`;
    const onValueChange = database()
      .ref(path)
      .on(
        "value",
        (snapshot) => {
          const bigBankLeaders = snapshot.val().filter((leader: BigBankLeader) => !!leader);
          args?.dispatch?.(bigBankLeadersReceived({ leaders: bigBankLeaders }));
          args?.onSuccess?.(snapshot);
        },
        (error) => {
          args?.onFailure?.(error);
          dlToast.error(error.message || "Failed to get big bank leaders");
        },
      );
    return { path, onValueChange };
  }

  public getChatMessages(args?: EndpointArgs) {
    const path = `/chat/global`;
    const onValueChange = database()
      .ref(path)
      .on(
        "value",
        (snapshot) => {
          const chatObjects = snapshot.val();
          const chatMessages =
            null == chatObjects
              ? []
              : Object.keys(chatObjects).map((k) => ({ ...chatObjects[k], id: k }));
          args?.dispatch?.(chatMessagesReceived({ chatMessages }));
          args?.onSuccess?.(snapshot);
        },
        (error) => {
          args?.onFailure?.(error);
          dlToast.error(error.message || "Failed to get chat messages");
        },
      );
    return { path, onValueChange };
  }
}

export const dbService = DbService.getInstance();
