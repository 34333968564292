import React from "react";
import { View } from "react-native";
import { useSelector } from "react-redux";

import { Heading } from "../../core/components/heading";
import { Paragraph } from "../../core/components/paragraph";
import { selectCurrentGame } from "../game.slice";

export interface ComeOutResultProps {}

export const ComeOutResult = (props: ComeOutResultProps) => {
  const game = useSelector(selectCurrentGame);
  if (!game) return null;

  return (
    <View className="justify-center w-full h-full p-5 space-y-8 bg-black">
      <View className="items-center space-y-2">
        <Heading _className="text-red-600 text-2xl" weight="bold">
          Come Out Roll
        </Heading>
        <Paragraph>You got a little more work to do.</Paragraph>
      </View>
      <View className="space-y-4">
        <View className="overflow-hidden border-2 border-yellow-600 rounded-lg">
          <View className="flex items-center p-3 bg-yellow-600">
            <Paragraph _className="text-black text-lg text-center" weight="bold">
              The Point Is
            </Paragraph>
          </View>
          <View className="items-center p-3 bg-black">
            <Heading _className="text-oppsGold text-2xl" weight="bold">
              {game.thePoint}
            </Heading>
          </View>
        </View>
        <View className="overflow-hidden border-2 border-yellow-600 rounded-lg">
          <View className="flex items-center p-3 bg-yellow-600">
            <Paragraph _className="text-black text-lg text-center" weight="bold">
              The Bet Is
            </Paragraph>
          </View>
          <View className="items-center p-3 bg-black">
            <Heading _className="text-oppsGold text-2xl" weight="bold">
              {game.betType}
            </Heading>
          </View>
        </View>
      </View>
    </View>
  );
};
