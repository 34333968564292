/** @type {import('tailwindcss').Config} */
module.exports = {
  content: ["./index.{js,jsx,ts,tsx}", "./src/**/*.{js,jsx,ts,tsx}"],
  theme: {
    extend: {
      colors: {
        oppsRed: "#E60000",
        oppsGold: "#E5A336",
        oppsBlack: "#0F0F10",
        oppsGrey: "#CCD1D5",
      },
      fontFamily: {
        robotoMono_100: ["RobotoMono_100Thin"],
        robotoMono_200: ["RobotoMono_200ExtraLight"],
        robotoMono_300: ["RobotoMono_300Light"],
        robotoMono_400: ["RobotoMono_400Regular"],
        robotoMono_500: ["RobotoMono_500Medium"],
        robotoMono_600: ["RobotoMono_600SemiBold"],
        robotoMono_700: ["RobotoMono_700Bold"],
        robotoMonoItalic_100: ["RobotoMono_100Thin_Italic"],
        robotoMonoItalic_200: ["RobotoMono_200ExtraLight_Italic"],
        robotoMonoItalic_300: ["RobotoMono_300Light_Italic"],
        robotoMonoItalic_400: ["RobotoMono_400Regular_Italic"],
        robotoMonoItalic_500: ["RobotoMono_500Medium_Italic"],
        robotoMonoItalic_600: ["RobotoMono_600SemiBold_Italic"],
        robotoMonoItalic_700: ["RobotoMono_700Bold_Italic"],
        spaceGrotesk_300: ["SpaceGrotesk_300Light"],
        spaceGrotesk_400: ["SpaceGrotesk_400Regular"],
        spaceGrotesk_500: ["SpaceGrotesk_500Medium"],
        spaceGrotesk_600: ["SpaceGrotesk_600SemiBold"],
        spaceGrotesk_700: ["SpaceGrotesk_700Bold"],
      },
    },
  },
  plugins: [],
};
