import Constants from "expo-constants";

/** Referencing `env` provides helpful auto-suggestions */
export const env = {
  diceLordApiBaseUrl: Constants.expoConfig?.extra?.diceLordApiBaseUrl,
  firebaseApiKey: Constants.expoConfig?.extra?.firebaseApiKey,
  firebaseAuthDomain: Constants.expoConfig?.extra?.firebaseAuthDomain,
  firebaseDatabaseURL: Constants.expoConfig?.extra?.firebaseDatabaseURL,
  firebaseProjectId: Constants.expoConfig?.extra?.firebaseProjectId,
  firebaseStorageBucket: Constants.expoConfig?.extra?.firebaseStorageBucket,
  firebaseMessagingSenderId: Constants.expoConfig?.extra?.firebaseMessagingSenderId,
  firebaseAppId: Constants.expoConfig?.extra?.firebaseAppId,
  firebaseMeasurementId: Constants.expoConfig?.extra?.firebaseMeasurementId,
};
