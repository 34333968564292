export const rollTrashTalks = [
  {
    id: "c8d3dfe4-e6be-4e2a-95f7-2d2ed59751ad",
    message: "Baby need a new pair of shoes!",
    imageMobile: "",
    imageDesktop: "",
    severity: 1,
    weight: 1,
  },
  {
    id: "63ff497b-06ef-41c1-9a4e-cb6c4bda3b1a",
    message: "Wifey need a BBL!",
    imageMobile: "",
    imageDesktop: "",
    severity: 1,
    weight: 1,
  },
  {
    id: "e4e565c8-6f20-42e3-a19d-4f847b02e6e0",
    message: "Hit my number, on GANG!",
    imageMobile: "",
    imageDesktop: "",
    severity: 1,
    weight: 1,
  },
  {
    id: "4c7ac0db-2db1-447f-8c3c-d63b3dfc1047",
    message: "Hit my number, on GOD!",
    imageMobile: "",
    imageDesktop: "",
    severity: 1,
    weight: 1,
  },
  {
    id: "f14d63c3-dc6c-4f5c-b0e3-2fb82002ec56",
    message: "Momma need a new house!",
    imageMobile: "",
    imageDesktop: "",
    severity: 1,
    weight: 1,
  },
];
