import clsx from "clsx";
import { LinearGradient } from "expo-linear-gradient";
import React from "react";
import { ScrollView, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { LeaderCard } from "./components/leader-card";
import { BigBankLeader, DownBadLeader, MostActiveLeader, WayUpLeader } from "./leaders.type";
import { colors } from "../../tw-config";
import { HeaderCard } from "../core/components/header-card";
import { Heading } from "../core/components/heading";
import { Paragraph } from "../core/components/paragraph";
import { SafeImage } from "../core/components/safe-image";
import { Strong } from "../core/components/strong";
import { formatCurrency } from "../core/utils/formatter.util";
import { isMobile, isWeb } from "../core/utils/platform.util";

export interface LeadersScreenProps {
  diceLord: WayUpLeader | undefined;
  wayUpLeaders: WayUpLeader[];
  downBadLeaders: DownBadLeader[];
  mostActiveLeaders: MostActiveLeader[];
  bigBankLeaders: BigBankLeader[];
}

export const LeadersScreen = (props: LeadersScreenProps) => {
  const { top, bottom } = useSafeAreaInsets();

  return (
    <>
      <LinearGradient
        colors={[colors.oppsGold, colors.oppsRed, colors.oppsBlack]}
        className="absolute w-full h-full"
      />
      <ScrollView
        className="flex-1 p-5 space-y-5 lg:px-40 lg:py-10"
        style={{ marginTop: top, marginBottom: bottom }}>
        <View className="items-center p-5 space-y-3 rounded-3xl bg-oppsBlack lg:flex-row lg:h-72">
          <Paragraph _className="text-lg text-center lg:basis-1/3">
            The <Strong _className="text-oppsGold">DICE LORD</Strong> is
          </Paragraph>
          <SafeImage
            fallback="avatar"
            className="w-full aspect-square rounded-3xl lg:basis-1/3 lg:rounded-full"
            source={{ uri: props.diceLord?.avatar }}
          />
          <View className="items-center lg:basis-1/3">
            <Heading weight="bold" _className="text-2xl text-oppsRed">
              {props.diceLord?.name}
            </Heading>
            <Paragraph _className="text-2xl">{formatCurrency(props.diceLord?.net)} ZBK</Paragraph>
          </View>
        </View>
        <View className={clsx({ "flex-row space-x-5": isWeb, "space-y-5": isMobile })}>
          <View className="lg:flex lg:flex-1">
            <HeaderCard title="WAY UP">
              {props.wayUpLeaders.map((leader, i) => (
                <View key={i}>
                  <LeaderCard
                    name={leader.name}
                    imageUri={leader.avatar}
                    points={`+${formatCurrency(leader.net)} ZBK`}
                    rank={leader.rank}
                  />
                </View>
              ))}
            </HeaderCard>
          </View>
          <View className="space-y-5 lg:flex lg:flex-1">
            <HeaderCard title="DOWN BAD" _header={{ className: "bg-blue-900" }}>
              {props.downBadLeaders.map((leader, i) => (
                <View key={i}>
                  <LeaderCard
                    name={leader.name}
                    imageUri={leader.avatar}
                    points={`${formatCurrency(leader.net)} ZBK`}
                    rank={leader.rank}
                  />
                </View>
              ))}
            </HeaderCard>
            <HeaderCard title="MOST ACTIVE" _header={{ className: "bg-blue-900" }}>
              {props.mostActiveLeaders.map((leader, i) => (
                <View key={i}>
                  <LeaderCard
                    name={leader.name}
                    imageUri={leader.avatar}
                    points={`${leader.gamesCount} Games played`}
                    rank={leader.rank}
                  />
                </View>
              ))}
            </HeaderCard>
            <HeaderCard title="BIG BANK" _header={{ className: "bg-blue-900" }}>
              {props.bigBankLeaders.map((leader, i) => (
                <View key={i}>
                  <LeaderCard
                    name={leader.name}
                    imageUri={leader.avatar}
                    points={`${formatCurrency(leader.bankrollBalance)} ZBK`}
                    rank={leader.rank}
                  />
                </View>
              ))}
            </HeaderCard>
          </View>
        </View>
        <View className="h-16" />
      </ScrollView>
    </>
  );
};
