export enum Routes {
  APP_STACK__HOME = "Home",
  APP_STACK__GENERAL_ERROR = "General Error",
  APP_STACK__GENERAL_LOADING = "General Loading",

  AUTH_STACK__SIGN_IN = "Sign in",
  AUTH_STACK__SIGN_UP = "Sign up",
  AUTH_STACK__FORGOT_PWD = "Forgot Password",
  AUTH_STACK__DEBUG_ENV = "Debug Environment",

  HOME_TAB = "Home Tab",
  HOME_TAB__LEADERS = "Leaders",
  HOME_TAB__BUILD = "Build",
  HOME_TAB__HOME = "Home",
  HOME_TAB__CHAT = "Chat",
  HOME_TAB__PROFILE = "Profile",

  PROFILE_STACK__PROFILE = "View Profile",
  PROFILE_STACK__EDIT_PROFILE = "Edit Profile",

  HOME_STACK__HOME = "Dashboard",
  HOME_STACK__BET = "Bet",
  HOME_STACK__GAME = "Game",
}
