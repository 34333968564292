import auth, { FirebaseAuthTypes } from "@react-native-firebase/auth";
import { StackScreenProps } from "@react-navigation/stack";
import React, { useState } from "react";

import { ForgotPasswordFormState, ForgotPasswordScreenProps } from "./forgot-password.screen";
import { AppStackParamList } from "../../navigation/navigators/app-stack.navigator";
import { Routes } from "../../navigation/routes";
import { dlToast } from "../core/utils/toast.util";

const initialState: ForgotPasswordFormState = {
  email: "",
};

type ContainerProps = StackScreenProps<AppStackParamList, Routes.AUTH_STACK__FORGOT_PWD>;

export const ForgotPasswordContainer = (Screen: React.FC<ForgotPasswordScreenProps>) =>
  function _(props: ContainerProps) {
    const [formState, setFormState] = useState<ForgotPasswordFormState>(initialState);
    const [isSendingResetPasswordEmail, setIsSendingResetPasswordEmail] = useState(false);
    const [isResetPasswordEmailSent, setIsResetPasswordEmailSent] = useState(false);

    return (
      <Screen
        title="Forgot Password"
        isSendingResetPasswordEmail={isSendingResetPasswordEmail}
        isResetPasswordEmailSent={isResetPasswordEmailSent}
        formState={formState}
        onChangeFormState={setFormState}
        onSendResetPasswordEmail={async () => {
          try {
            const { email } = formState;
            if (!email) {
              throw new Error("Invalid email");
            }
            setIsSendingResetPasswordEmail(true);
            await auth().sendPasswordResetEmail(email);
            setIsResetPasswordEmailSent(true);
          } catch (e) {
            const error = e as FirebaseAuthTypes.NativeFirebaseAuthError;
            dlToast.error(error.message);
          } finally {
            setIsSendingResetPasswordEmail(false);
          }
        }}
        onBackToSignIn={() => {
          props.navigation.navigate(Routes.AUTH_STACK__SIGN_IN);
        }}
      />
    );
  };
