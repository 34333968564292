import auth from "@react-native-firebase/auth";
import { StackScreenProps } from "@react-navigation/stack";
import React from "react";
import { useDispatch } from "react-redux";

import { HomeScreenProps } from "./home.screen";
import { HomeStackParamList } from "../../navigation/navigators/home-stack.navigator";
import { Routes } from "../../navigation/routes";
import { useGamesControllerStartGameMutation } from "../../services/api/api-service";
import { selectAppVersion } from "../../store/app.slice";
import { loggedOut, useAppSelector } from "../../store/store";
import { useCurrentPlayer } from "../core/hooks/use-current-player";
import { dlToast } from "../core/utils/toast.util";

type ContainerProps = StackScreenProps<HomeStackParamList, Routes.HOME_STACK__HOME>;

export const HomeContainer = (Screen: React.FC<HomeScreenProps>) => {
  return function _(porps: ContainerProps) {
    const dispatch = useDispatch();

    const userId = auth().currentUser?.uid;

    const version = useAppSelector(selectAppVersion);
    const { player } = useCurrentPlayer();
    const [startGame, { isLoading }] = useGamesControllerStartGameMutation();

    return (
      <Screen
        isLoading={isLoading}
        version={version}
        player={player}
        onSignOut={async () => {
          await auth().signOut();
          dispatch(loggedOut());
        }}
        onPlay={async () => {
          if (!userId) return;
          try {
            await startGame({ playerId: userId }).unwrap();
            porps.navigation.navigate(Routes.HOME_STACK__BET);
          } catch (e: any) {
            dlToast.error(e.message || "Failed to start game");
          }
        }}
      />
    );
  };
};
