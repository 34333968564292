import { emptyApi as api } from "./empty-api-service";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    appControllerGetDefault: build.query<
      AppControllerGetDefaultApiResponse,
      AppControllerGetDefaultApiArg
    >({
      query: () => ({ url: `/` }),
    }),
    appControllerGetHello: build.query<
      AppControllerGetHelloApiResponse,
      AppControllerGetHelloApiArg
    >({
      query: () => ({ url: `/hello` }),
    }),
    playersControllerCreate: build.mutation<
      PlayersControllerCreateApiResponse,
      PlayersControllerCreateApiArg
    >({
      query: (queryArg) => ({ url: `/players`, method: "POST", body: queryArg.createPlayerDto }),
    }),
    playersControllerFindAll: build.query<
      PlayersControllerFindAllApiResponse,
      PlayersControllerFindAllApiArg
    >({
      query: () => ({ url: `/players` }),
    }),
    playersControllerFindOne: build.query<
      PlayersControllerFindOneApiResponse,
      PlayersControllerFindOneApiArg
    >({
      query: (queryArg) => ({ url: `/players/${queryArg.playerId}` }),
    }),
    playersControllerUpdateProfile: build.mutation<
      PlayersControllerUpdateProfileApiResponse,
      PlayersControllerUpdateProfileApiArg
    >({
      query: (queryArg) => ({
        url: `/players/${queryArg.playerId}`,
        method: "PATCH",
        body: queryArg.playerProfileDto,
      }),
    }),
    chatControllerPost: build.mutation<ChatControllerPostApiResponse, ChatControllerPostApiArg>({
      query: (queryArg) => ({
        url: `/chatboards/${queryArg.chatBoardId}/messages`,
        method: "POST",
        body: queryArg.createChatMessageDto,
      }),
    }),
    chatControllerGet: build.query<ChatControllerGetApiResponse, ChatControllerGetApiArg>({
      query: (queryArg) => ({ url: `/chatboards/${queryArg.chatBoardId}/messages` }),
    }),
    gamesControllerGet: build.query<GamesControllerGetApiResponse, GamesControllerGetApiArg>({
      query: (queryArg) => ({ url: `/games/${queryArg.gameId}` }),
    }),
    gamesControllerStartGame: build.mutation<
      GamesControllerStartGameApiResponse,
      GamesControllerStartGameApiArg
    >({
      query: (queryArg) => ({ url: `/players/${queryArg.playerId}/startgame`, method: "POST" }),
    }),
    gamesControllerPlaceBet: build.mutation<
      GamesControllerPlaceBetApiResponse,
      GamesControllerPlaceBetApiArg
    >({
      query: (queryArg) => ({
        url: `/players/${queryArg.playerId}/placebet`,
        method: "PUT",
        body: queryArg.betDto,
      }),
    }),
    gamesControllerShoot: build.mutation<
      GamesControllerShootApiResponse,
      GamesControllerShootApiArg
    >({
      query: (queryArg) => ({ url: `/players/${queryArg.playerId}/shoot`, method: "PUT" }),
    }),
    settingsControllerRemove: build.mutation<
      SettingsControllerRemoveApiResponse,
      SettingsControllerRemoveApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/accountdata/${queryArg.playerId}`,
        method: "DELETE",
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as api };
export type AppControllerGetDefaultApiResponse = unknown;
export type AppControllerGetDefaultApiArg = void;
export type AppControllerGetHelloApiResponse = unknown;
export type AppControllerGetHelloApiArg = void;
export type PlayersControllerCreateApiResponse = /** status 201 Player created */ PlayerDto;
export type PlayersControllerCreateApiArg = {
  createPlayerDto: CreatePlayerDto;
};
export type PlayersControllerFindAllApiResponse = /** status 200 Players found */ PlayerDto[];
export type PlayersControllerFindAllApiArg = void;
export type PlayersControllerFindOneApiResponse = /** status 200 Player found */ PlayerDto;
export type PlayersControllerFindOneApiArg = {
  playerId: string;
};
export type PlayersControllerUpdateProfileApiResponse = unknown;
export type PlayersControllerUpdateProfileApiArg = {
  playerId: string;
  playerProfileDto: PlayerProfileDto;
};
export type ChatControllerPostApiResponse =
  /** status 201 Chat Message created */ CreateChatMessageDto;
export type ChatControllerPostApiArg = {
  chatBoardId: string;
  createChatMessageDto: CreateChatMessageDto;
};
export type ChatControllerGetApiResponse =
  /** status 200 Chatboard Messages found */ ChatMessageDto[];
export type ChatControllerGetApiArg = {
  chatBoardId: string;
};
export type GamesControllerGetApiResponse = /** status 200 Game found */ GameDto;
export type GamesControllerGetApiArg = {
  gameId: string;
};
export type GamesControllerStartGameApiResponse = /** status 201 Game created */ GameDto;
export type GamesControllerStartGameApiArg = {
  playerId: string;
};
export type GamesControllerPlaceBetApiResponse = /** status 200 Game updated */ GameDto;
export type GamesControllerPlaceBetApiArg = {
  playerId: string;
  betDto: BetDto;
};
export type GamesControllerShootApiResponse = /** status 200 Game updated */ GameDto;
export type GamesControllerShootApiArg = {
  playerId: string;
};
export type SettingsControllerRemoveApiResponse = unknown;
export type SettingsControllerRemoveApiArg = {
  playerId: string;
};
export type PlayerDto = {
  playerId: string;
  created: string;
  name: string;
  avatar: string;
  currentGame: string;
  gamesCount: number;
  bankrollBalance: number;
  bankrollTotalWon: number;
  bankrollTotalLost: number;
  bankrollNet: number;
};
export type CreatePlayerDto = {
  playerId: string;
  name: string;
  avatar: string;
};
export type PlayerProfileDto = {
  name: string;
  avatar: string;
};
export type CreateChatMessageDto = {
  message: string;
  playerId: string;
  name: string;
  avatar: string;
};
export type ChatMessageDto = {
  message: string;
  timestamp: number;
  playerId: string;
  name: string;
  avatar: string;
};
export type GameDto = {
  id: string;
  gameOver: boolean;
  shooterId: string;
  betAmount: number;
  betType: string;
  shooterWins: boolean;
  winnerPaid: boolean;
  comeOut: object;
  thePoint: number;
  rolls: string[];
};
export type BetDto = {
  betAmount: number;
  betType: string;
};
export const {
  useAppControllerGetDefaultQuery,
  useLazyAppControllerGetDefaultQuery,
  useAppControllerGetHelloQuery,
  useLazyAppControllerGetHelloQuery,
  usePlayersControllerCreateMutation,
  usePlayersControllerFindAllQuery,
  useLazyPlayersControllerFindAllQuery,
  usePlayersControllerFindOneQuery,
  useLazyPlayersControllerFindOneQuery,
  usePlayersControllerUpdateProfileMutation,
  useChatControllerPostMutation,
  useChatControllerGetQuery,
  useLazyChatControllerGetQuery,
  useGamesControllerGetQuery,
  useLazyGamesControllerGetQuery,
  useGamesControllerStartGameMutation,
  useGamesControllerPlaceBetMutation,
  useGamesControllerShootMutation,
  useSettingsControllerRemoveMutation,
} = injectedRtkApi;
