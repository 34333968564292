import classNames from "clsx";
import { LinearGradient } from "expo-linear-gradient";
import React from "react";
import { KeyboardAvoidingView, Platform, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { colors } from "../../../tw-config";
import { ViewPropsCls } from "../types/class-name";

export interface BackgroundProps extends ViewPropsCls {
  keyboardAvoiding?: boolean;
}

export const Background = ({ _className, style, keyboardAvoiding, ...props }: BackgroundProps) => {
  const { top, bottom } = useSafeAreaInsets();

  const Wrapper = keyboardAvoiding ? KeyboardAvoidingView : View;

  return (
    <>
      <LinearGradient
        colors={[colors.oppsGold, colors.oppsRed, colors.oppsBlack]}
        className="absolute w-full h-full"
      />
      <Wrapper
        {...props}
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        className={classNames("flex-1 p-5 space-y-5 lg:p-40", _className)}
        style={[{ marginTop: top, marginBottom: bottom }, style]}>
        {props.children}
      </Wrapper>
    </>
  );
};
