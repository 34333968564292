import auth, { FirebaseAuthTypes } from "@react-native-firebase/auth";
import { StackScreenProps } from "@react-navigation/stack";
import React, { useState } from "react";

import { SignUpFormState, SignUpScreenProps } from "./sign-up.screen";
import { AppStackParamList } from "../../navigation/navigators/app-stack.navigator";
import { Routes } from "../../navigation/routes";
import { dlToast } from "../core/utils/toast.util";

const initialState: SignUpFormState = {
  email: "",
  password: "",
  confirmPassword: "",
};

type ContainerProps = StackScreenProps<AppStackParamList, Routes.AUTH_STACK__SIGN_UP>;

export const SignUpContainer = (Screen: React.FC<SignUpScreenProps>) =>
  function _(props: ContainerProps) {
    const [formState, setFormState] = useState<SignUpFormState>(initialState);
    const [isSigningUp, setIsSigningUp] = useState(false);

    return (
      <Screen
        formState={formState}
        onChangeFormState={setFormState}
        isSigningUp={isSigningUp}
        onSignUp={async () => {
          try {
            const { email, password, confirmPassword } = formState;
            if (!email || !password) {
              throw new Error("Invalid email or password");
            }
            if (password !== confirmPassword) {
              throw new Error("Your passwords don't match");
            }
            await auth().createUserWithEmailAndPassword(email, password);
          } catch (e) {
            const error = e as FirebaseAuthTypes.NativeFirebaseAuthError;
            dlToast.error(error.message);
          } finally {
            setIsSigningUp(false);
          }
        }}
        onBackToSignIn={() => {
          props.navigation.navigate(Routes.AUTH_STACK__SIGN_IN);
        }}
      />
    );
  };
