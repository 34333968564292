import auth from "@react-native-firebase/auth";
import { StackScreenProps } from "@react-navigation/stack";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { BetScreenProps } from "./bet.screen";
import { currentGameAdded } from "./game.slice";
import { GameDto } from "../../models/game";
import { HomeStackParamList } from "../../navigation/navigators/home-stack.navigator";
import { Routes } from "../../navigation/routes";
import { useGamesControllerPlaceBetMutation } from "../../services/api/api-service";

type ContainerProps = StackScreenProps<HomeStackParamList, Routes.HOME_STACK__BET>;

export const BetContainer = (Screen: React.FC<BetScreenProps>) =>
  function _(props: ContainerProps) {
    const dispatch = useDispatch();

    const userId = auth().currentUser?.uid;
    const [step, setStep] = useState<"place" | "confirm">("place");

    const [placeBet, { isLoading, data }] = useGamesControllerPlaceBetMutation();

    return (
      <Screen
        game={data as GameDto}
        isLoading={isLoading}
        step={step}
        onPlaceBet={async (values) => {
          if (!userId) return;
          // Perform validation here?
          const result = await placeBet({
            playerId: userId,
            betDto: {
              betAmount: values.betAmount,
              betType: values.betType,
            },
          }).unwrap();
          dispatch(currentGameAdded(result as GameDto));
          setStep("confirm");
        }}
        onAcknowledge={() => {
          props.navigation.navigate(Routes.HOME_STACK__GAME);
        }}
      />
    );
  };
