import { NavigationContainer } from "@react-navigation/native";
import * as Linking from "expo-linking";
import React from "react";

import { AppStackNavigator } from "./navigators/app-stack.navigator";
import { Paragraph } from "../modules/core/components/paragraph";

const prefix = Linking.createURL("/");

export const NavContainer = () => {
  const linking = {
    prefixes: [prefix],
  };

  return (
    <NavigationContainer linking={linking} fallback={<Paragraph>Loading...</Paragraph>}>
      <AppStackNavigator />
    </NavigationContainer>
  );
};
