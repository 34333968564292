import { Ionicons } from "@expo/vector-icons";
import clsx from "clsx";
import * as ImagePicker from "expo-image-picker";
import { LinearGradient } from "expo-linear-gradient";
import React, { Dispatch, SetStateAction } from "react";
import { TouchableOpacity, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { Player } from "../../models/player";
import { colors } from "../../tw-config";
import { Button } from "../core/components/button";
import { HEADING_FONTS } from "../core/components/heading";
import { SafeImage } from "../core/components/safe-image";
import { TextField } from "../core/components/text-field";

export interface EditProfileFormState {
  name: string;
  avatarUri: string;
}

export interface EditProfileScreenProps {
  player: Player | null;
  isFormDisabled: boolean;
  isLoading: boolean;
  formState: EditProfileFormState;
  onChangeFormState: Dispatch<SetStateAction<EditProfileFormState>>;
  onUpdateProfile: () => void;
  onGoBack: () => void;
}

export const EditProfileScreen = (props: EditProfileScreenProps) => {
  const { top, bottom } = useSafeAreaInsets();

  const handleAvatarPress = async () => {
    // No permissions request is necessary for launching the image library
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });
    if (!result.canceled) {
      props.onChangeFormState((prev) => ({
        ...prev,
        avatarUri: result.assets[0].uri,
      }));
    }
  };

  return (
    <>
      <LinearGradient
        colors={[colors.oppsGold, colors.oppsRed, colors.oppsBlack]}
        className="absolute w-full h-full"
      />
      <View
        className="flex-1 px-5 pb-5 space-y-5 lg:p-40"
        style={{ marginTop: top, marginBottom: bottom }}>
        <Button
          variant="ghost"
          size="sm"
          _className="block lg:hidden self-start -mx-4"
          icon={<Ionicons name="arrow-back" size={24} color="white" className="items-center" />}
          onPress={props.onGoBack}>
          Go back
        </Button>
        <View
          className={clsx([
            "items-center p-5 overflow-hidden space-y-3 rounded-3xl bg-oppsBlack lg:flex-row lg:p-0 lg:h-72",
          ])}>
          <TouchableOpacity
            disabled={props.isFormDisabled}
            className="h-[112px] rounded-full lg:rounded-none aspect-square lg:h-full bg-white overflow-hidden"
            onPress={handleAvatarPress}>
            <SafeImage
              fallback="avatar"
              className="w-full h-full"
              source={{ uri: props.formState.avatarUri }}
            />
          </TouchableOpacity>
          <TextField
            editable={!props.isFormDisabled}
            _className={clsx("text-2xl text-oppsRed", HEADING_FONTS.bold)}
            _containerClassName="self-stretch flex lg:flex-1 lg:p-5 lg:justify-center"
            placeholder="Enter your name"
            value={props.formState.name}
            onChangeText={(name) => {
              if (/^[a-zA-Z0-9 ]*$/.test(name)) {
                return props.onChangeFormState((prev) => ({ ...prev, name }));
              }
            }}
            textAlign="center"
            multiline={false}
            numberOfLines={1}
            maxLength={25}
            returnKeyType="none"
          />
          <View className="self-stretch lg:h-full lg:p-10 lg:justify-between">
            <Button
              variant="ghost"
              size="sm"
              _className="hidden lg:flex self-end"
              onPress={props.onGoBack}>
              <Ionicons name="arrow-back" size={24} color="white" className="items-center" /> Go
              back
            </Button>
            <Button
              variant="solid"
              _className="w-full"
              disabled={props.isFormDisabled}
              isLoading={props.isLoading}
              onPress={props.onUpdateProfile}>
              Update profile
            </Button>
          </View>
        </View>
      </View>
    </>
  );
};
