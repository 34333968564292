import { useFocusEffect } from "@react-navigation/native";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { LeadersScreenProps } from "./leaders.screen";
import {
  selectBigBankLeaders,
  selectDownBadLeaders,
  selectMostActiveLeaders,
  selectWayUpLeaders,
} from "./leaders.slice";
import { dbService } from "../../services/db-service";

export const LeadersContainer = (Screen: React.FC<LeadersScreenProps>) =>
  function _() {
    const dispatch = useDispatch();

    const wayUpLeaders = useSelector(selectWayUpLeaders);
    const downBadLeaders = useSelector(selectDownBadLeaders);
    const mostActiveLeaders = useSelector(selectMostActiveLeaders);
    const bigBankLeaders = useSelector(selectBigBankLeaders);

    useFocusEffect(
      useCallback(() => {
        const { path, onValueChange } = dbService.getWayUpLeaders({ dispatch });
        // Stop listening for updates when no longer required
        return () => dbService.cleanupValueEvent(path, onValueChange);
      }, [dispatch]),
    );

    useFocusEffect(
      useCallback(() => {
        const { path, onValueChange } = dbService.getDownBadLeaders({ dispatch });
        return () => dbService.cleanupValueEvent(path, onValueChange);
      }, [dispatch]),
    );

    useFocusEffect(
      useCallback(() => {
        const { path, onValueChange } = dbService.getMostActiveLeaders({ dispatch });
        return () => dbService.cleanupValueEvent(path, onValueChange);
      }, [dispatch]),
    );

    useFocusEffect(
      useCallback(() => {
        const { path, onValueChange } = dbService.getBigBankLeaders({ dispatch });
        return () => dbService.cleanupValueEvent(path, onValueChange);
      }, [dispatch]),
    );

    return (
      <Screen
        diceLord={wayUpLeaders?.[0]}
        wayUpLeaders={wayUpLeaders}
        downBadLeaders={downBadLeaders}
        mostActiveLeaders={mostActiveLeaders}
        bigBankLeaders={bigBankLeaders}
      />
    );
  };
