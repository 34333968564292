import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Player } from "../../models/player";
import { RootState } from "../../store/store";

type ProfileSliceState = {
  player: Player | null;
};

const initialState: ProfileSliceState = {
  player: null,
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    // Model reducers as events, not setters
    profileAdded(state, action: PayloadAction<Player>) {
      state.player = action.payload;
    },
  },
});

export const { profileAdded } = profileSlice.actions;

// Define selectors alongside reducers
// Selectors should be named as "selectThing"

/**
 * Reminder: Use useCurrentPlayer hook to get current player in real-time
 */
export const selectProfile = (state: RootState) => state.profile.player;
