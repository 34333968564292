import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { BigBankLeader, DownBadLeader, MostActiveLeader, WayUpLeader } from "./leaders.type";
import { RootState } from "../../store/store";

const wayUpLeadersAdapter = createEntityAdapter<WayUpLeader>({
  selectId: ({ playerId }) => playerId,
  sortComparer: (a, b) => a.rank - b.rank,
});
const downBadLeadersAdapter = createEntityAdapter<DownBadLeader>({
  selectId: ({ playerId }) => playerId,
  sortComparer: (a, b) => a.rank - b.rank,
});
const mostActiveLeadersAdapter = createEntityAdapter<MostActiveLeader>({
  selectId: ({ playerId }) => playerId,
  sortComparer: (a, b) => a.rank - b.rank,
});
const bigBankLeadersAdapter = createEntityAdapter<BigBankLeader>({
  selectId: ({ playerId }) => playerId,
  sortComparer: (a, b) => a.rank - b.rank,
});

const wayUpLeadersInitialState = wayUpLeadersAdapter.getInitialState();
const downBadLeadersInitialState = downBadLeadersAdapter.getInitialState();
const mostActiveLeadersInitialState = mostActiveLeadersAdapter.getInitialState();
const bigBankLeadersInitialState = bigBankLeadersAdapter.getInitialState();

export const leadersSlice = createSlice({
  name: "leaders",
  initialState: {
    wayUpLeaders: wayUpLeadersInitialState,
    downBadLeaders: downBadLeadersInitialState,
    mostActiveLeaders: mostActiveLeadersInitialState,
    bigBankLeaders: bigBankLeadersInitialState,
  },
  reducers: {
    // Model actions as events, not setters
    // https://redux.js.org/style-guide/#model-actions-as-events-not-setters
    wayUpLeadersReceived(state, action: PayloadAction<{ leaders: WayUpLeader[] }>) {
      wayUpLeadersAdapter.setAll(state.wayUpLeaders, action.payload.leaders);
    },
    downBadLeadersReceived(state, action: PayloadAction<{ leaders: DownBadLeader[] }>) {
      downBadLeadersAdapter.setAll(state.downBadLeaders, action.payload.leaders);
    },
    mostActiveLeadersReceived(state, action: PayloadAction<{ leaders: MostActiveLeader[] }>) {
      mostActiveLeadersAdapter.setAll(state.mostActiveLeaders, action.payload.leaders);
    },
    bigBankLeadersReceived(state, action: PayloadAction<{ leaders: BigBankLeader[] }>) {
      bigBankLeadersAdapter.setAll(state.bigBankLeaders, action.payload.leaders);
    },
  },
});

export const {
  wayUpLeadersReceived,
  downBadLeadersReceived,
  mostActiveLeadersReceived,
  bigBankLeadersReceived,
} = leadersSlice.actions;

// Define selectors alongside reducers:
// https://redux.js.org/usage/deriving-data-selectors#define-selectors-alongside-reducers

export const wayUpLeaderSelectors = wayUpLeadersAdapter.getSelectors<RootState>(
  (state) => state.leaders.wayUpLeaders,
);
export const downBadLeaderSelectors = downBadLeadersAdapter.getSelectors<RootState>(
  (state) => state.leaders.downBadLeaders,
);
export const mostActiveLeaderSelectors = mostActiveLeadersAdapter.getSelectors<RootState>(
  (state) => state.leaders.mostActiveLeaders,
);
export const bigBankLeaderSelectors = bigBankLeadersAdapter.getSelectors<RootState>(
  (state) => state.leaders.bigBankLeaders,
);

// Name selector functions as "selectThing"
// https://redux.js.org/style-guide/#name-selector-functions-as-selectthing

export const { selectAll: selectWayUpLeaders, selectById: selectWayUpLeaderById } =
  wayUpLeaderSelectors;
export const { selectAll: selectDownBadLeaders, selectById: selectDownBadLeaderById } =
  downBadLeaderSelectors;
export const { selectAll: selectMostActiveLeaders, selectById: selectMostActiveLeaderById } =
  mostActiveLeaderSelectors;
export const { selectAll: selectBigBankLeaders, selectById: selectBigBankLeaderById } =
  bigBankLeaderSelectors;
