import clsx from "clsx";
import React from "react";
import { Text } from "react-native";

import { TextPropsCls } from "../types/class-name";

type Weight = "light" | "regular" | "medium" | "semibold" | "bold";

export const HEADING_FONTS: { [weight in Weight]: string } = {
  light: "font-spaceGrotesk_300",
  regular: "font-spaceGrotesk_400",
  medium: "font-spaceGrotesk_500",
  semibold: "font-spaceGrotesk_600",
  bold: "font-spaceGrotesk_700",
};

export interface HeadingProps extends TextPropsCls {
  weight?: Weight;
}

export const Heading = ({ _className, weight = "regular", ...rest }: HeadingProps) => {
  const font = HEADING_FONTS[weight];
  const classes = clsx([font, "text-white", _className]);
  return <Text className={classes} {...rest} />;
};
