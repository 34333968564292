import classNames from "clsx";
import React, { ReactNode } from "react";
import { View } from "react-native";

import { Paragraph } from "../../core/components/paragraph";
import { Strong } from "../../core/components/strong";
import { ViewPropsCls } from "../../core/types/class-name";

export interface StatsRowProps extends ViewPropsCls {
  title: ReactNode;
  value: ReactNode;
  label: ReactNode;
  highlight?: boolean;
}

export const StatsRow = ({ title, value, label, _className, ...restProps }: StatsRowProps) => {
  return (
    <View className={classNames(["flex flex-row space-x-4 lg:w-1/2", _className])} {...restProps}>
      <Paragraph _className="flex-1 text-right text-oppsGold">{title}</Paragraph>
      <View
        className={classNames(
          "flex flex-row space-x-2 basis-1/2 items-end",
          restProps.highlight && "bg-oppsRed",
        )}>
        <Strong>{value}</Strong>
        <Paragraph _className="text-xs">{label}</Paragraph>
      </View>
    </View>
  );
};
