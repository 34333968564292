import auth from "@react-native-firebase/auth";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { env } from "../../env";

export const emptyApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: env.diceLordApiBaseUrl,
    mode: "cors",
    prepareHeaders: async (headers) => {
      const token = await auth().currentUser?.getIdToken();
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
        return headers;
      }
    },
  }),
  endpoints: () => ({}),
});
