import AsyncStorage from "@react-native-async-storage/async-storage";
import { combineReducers, configureStore, createAction } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { persistReducer, persistStore } from "redux-persist";
import { PersistConfig } from "redux-persist/es/types";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

import { appSlice } from "./app.slice";
import { chatSlice } from "../modules/chat/chat.slice";
import { gameSlice } from "../modules/game/game.slice";
import { leadersSlice } from "../modules/leaders/leaders.slice";
import { profileSlice } from "../modules/profile/profile.slice";
import { api } from "../services/api/enhanced-api-service";

export const loggedOut = createAction("app/loggedOut");

// redux persis config
const reduxPersistConfig: PersistConfig<any> = {
  key: "root",
  version: 1,
  storage: AsyncStorage,
  stateReconciler: autoMergeLevel2,
};

const reducers = combineReducers({
  app: appSlice.reducer,
  [api.reducerPath]: api.reducer,
  leaders: leadersSlice.reducer,
  game: gameSlice.reducer,
  profile: profileSlice.reducer,
  chat: chatSlice.reducer,
});

const rootReducer = (state: ReturnType<typeof reducers> | undefined, action: { type: string }) => {
  if (action.type === loggedOut.toString()) {
    // Reset state when loggedOut action is invoked
    AsyncStorage.clear();
    state = undefined;
  }
  return reducers(state, action);
};

const persistedReducer = persistReducer(reduxPersistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(api.middleware),
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof reducers>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
