import { Ionicons } from "@expo/vector-icons";
import { LinearGradient } from "expo-linear-gradient";
import React, { Dispatch, SetStateAction } from "react";
import { KeyboardAvoidingView, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { colors } from "../../tw-config";
import { Button } from "../core/components/button";
import { Heading } from "../core/components/heading";
import { Paragraph } from "../core/components/paragraph";
import { TextField } from "../core/components/text-field";

export interface SignUpFormState {
  email: string;
  password: string;
  confirmPassword: string;
}

export interface SignUpScreenProps {
  formState: SignUpFormState;
  onChangeFormState: Dispatch<SetStateAction<SignUpFormState>>;
  isSigningUp: boolean;
  onSignUp: () => void;
  onBackToSignIn: () => void;
}

export const SignUpScreen = (props: SignUpScreenProps) => {
  const { top, bottom } = useSafeAreaInsets();

  return (
    <>
      <LinearGradient
        colors={[colors.oppsGold, colors.oppsRed, colors.oppsBlack]}
        className="absolute w-full h-full"
      />
      <KeyboardAvoidingView
        behavior="height"
        className="flex items-center justify-center flex-1 p-5 lg:p-40"
        style={{ marginTop: top, marginBottom: bottom }}>
        <View className="flex w-full space-y-8 md:min-w-max md:max-w-md">
          <View className="space-y-2">
            <Heading weight="bold" _className="text-3xl">
              Welcome
            </Heading>
            <Paragraph>Sign up to continue!</Paragraph>
          </View>
          <View className="p-5 space-y-4 bg-oppsBlack rounded-3xl">
            <TextField
              label="Email"
              keyboardType="email-address"
              value={props.formState.email}
              onChangeText={(email) =>
                props.onChangeFormState((prev) => ({
                  ...prev,
                  email,
                }))
              }
            />
            <TextField
              label="Password"
              secureTextEntry
              value={props.formState.password}
              onChangeText={(password) =>
                props.onChangeFormState((prev) => ({
                  ...prev,
                  password,
                }))
              }
            />
            <TextField
              label="Confirm password"
              secureTextEntry
              value={props.formState.confirmPassword}
              onChangeText={(confirmPassword) =>
                props.onChangeFormState((prev) => ({
                  ...prev,
                  confirmPassword,
                }))
              }
            />
            <Button
              variant="solid"
              onPress={props.onSignUp}
              isLoading={props.isSigningUp}
              disabled={props.isSigningUp}>
              Sign up
            </Button>
            <View className="flex flex-row items-center justify-center space-x-2">
              <Paragraph>Already have an account?</Paragraph>
              <Button
                variant="link"
                size="xs"
                onPress={props.onBackToSignIn}
                disabled={props.isSigningUp}>
                Sign in
              </Button>
            </View>
          </View>
          {/* <View className="space-y-4">
            <View className="flex flex-row items-center space-x-4">
              <View className="flex flex-1 h-[1px] bg-gray-300" />
              <Paragraph>Or continue with</Paragraph>
              <View className="flex flex-1 h-[1px] bg-gray-300" />
            </View>
            <View className="flex flex-row items-center justify-center space-x-4">
              <Button
                variant="solid"
                _className="flex flex-1 bg-white"
                disabled={props.isSigningUp}>
                <Ionicons name="logo-google" size={24} color="black" />
              </Button>
              <Button
                variant="solid"
                _className="flex flex-1 bg-white"
                disabled={props.isSigningUp}>
                <Ionicons name="logo-facebook" size={24} color="black" />
              </Button>
              <Button
                variant="solid"
                _className="flex flex-1 bg-white"
                disabled={props.isSigningUp}>
                <Ionicons name="logo-twitter" size={24} color="black" />
              </Button>
              <Button
                variant="solid"
                _className="flex flex-1 bg-white"
                disabled={props.isSigningUp}>
                <Ionicons name="logo-apple" size={24} color="black" />
              </Button>
            </View>
          </View> */}
        </View>
      </KeyboardAvoidingView>
    </>
  );
};
