import { api } from "./api-service";

export const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    chatControllerPost: {
      query: (queryArg) => ({
        // Copied from generated endpoint
        url: `/chatboards/${queryArg.chatBoardId}/messages`,
        method: "POST",
        body: queryArg.createChatMessageDto,
        // https://github.com/reduxjs/redux-toolkit/issues/2440
        responseHandler: (response: { text: () => any }) => response.text(),
      }),
    },
  },
});

export { enhancedApi as api };
