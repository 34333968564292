export const winTrashTalks = [
  {
    id: "f2c6d71c-72ab-497e-9269-b149cb971f5e",
    message: "Congratulations!!",
    imageMobile: require("../../../assets/images/00-hero.png"),
    imageDesktop: require("../../../assets/images/00-hero-desktop.png"),
    severity: 1,
    weight: 1,
  },
  {
    id: "e7f2ea7b-5646-4ab0-b450-833e0da4c3c3",
    message: "Get your money, gang!!",
    imageMobile: require("../../../assets/images/00-hero.png"),
    imageDesktop: require("../../../assets/images/00-hero-desktop.png"),
    severity: 1,
    weight: 1,
  },
  {
    id: "1a2c9f11-3b93-44da-8b35-9c84b33a6f16",
    message: "Way to do it, BIG DAWG!",
    imageMobile: require("../../../assets/images/00-hero.png"),
    imageDesktop: require("../../../assets/images/00-hero-desktop.png"),
    severity: 1,
    weight: 1,
  },
];
