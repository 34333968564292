import clsx from "clsx";
import React from "react";
import { Text } from "react-native";

import { TextPropsCls } from "../types/class-name";

type Weight = "thin" | "extralight" | "light" | "regular" | "medium" | "semibold" | "bold";

export const PARAGRAPH_FONTS: { [weight in Weight]: string } = {
  thin: "font-robotoMono_100",
  extralight: "font-robotoMono_200",
  light: "font-robotoMono_300",
  regular: "font-robotoMono_400",
  medium: "font-robotoMono_500",
  semibold: "font-robotoMono_600",
  bold: "font-robotoMono_700",
};

export interface ParagraphProps extends TextPropsCls {
  weight?: Weight;
}

export const Paragraph = ({ _className, weight = "regular", ...rest }: ParagraphProps) => {
  const font = PARAGRAPH_FONTS[weight];
  const classes = clsx([font, "text-white", _className]);
  return <Text className={classes} {...rest} />;
};
