import { Feather } from "@expo/vector-icons";
import clsx from "clsx";
import React, { useState } from "react";
import { View } from "react-native";

import { Button } from "../core/components/button";
import { PARAGRAPH_FONTS } from "../core/components/paragraph";
import { TextField } from "../core/components/text-field";
import { ViewPropsCls } from "../core/types/class-name";

export interface ChatInputProps extends ViewPropsCls {
  isLoading: boolean;
  onSend: (message: string, cb: { onSuccess: () => void }) => void;
}

export const ChatInput = ({ _className, ...restProps }: ChatInputProps) => {
  const [message, setMessage] = useState("");

  const handleSend = () => {
    restProps.onSend(message, {
      onSuccess() {
        setMessage("");
      },
    });
  };

  return (
    <View
      {...restProps}
      className={clsx([
        "flex flex-row overflow-hidden bg-gray-400/25 basis-1/12 rounded-xl",
        _className,
      ])}>
      <TextField
        _containerClassName="flex basis-3/4"
        _className={clsx(
          "flex flex-1 w-full h-full rounded-none border-0",
          PARAGRAPH_FONTS.regular,
        )}
        numberOfLines={1}
        returnKeyType="send"
        value={message}
        onChangeText={setMessage}
        onSubmitEditing={handleSend}
        editable={!restProps.isLoading}
        maxLength={256}
      />
      <Button
        variant="solid"
        _className="flex flex-1 h-full rounded-none"
        size="sm"
        icon={<Feather name="send" color="white" size={18} />}
        onPress={handleSend}
        disabled={restProps.isLoading || !message.length}
        isLoading={restProps.isLoading}>
        Send
      </Button>
    </View>
  );
};
