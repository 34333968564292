import clsx from "clsx";
import React, { ReactNode } from "react";
import { View } from "react-native";

import { Paragraph } from "../../core/components/paragraph";
import { SafeImage } from "../../core/components/safe-image";
import { ViewPropsCls } from "../../core/types/class-name";
import { formatCurrency } from "../../core/utils/formatter.util";

export interface ProfileCardProps extends Pick<ViewPropsCls, "_className" | "style"> {
  avatar?: string;
  name: string;
  balance?: number;
  actions?: ReactNode;
  secondaryActions?: ReactNode;
  horizontal?: boolean;
  ending?: ReactNode;
  hideAvatar?: boolean;
}

export const ProfileCard = (props: ProfileCardProps) => {
  const { _className, style } = props;

  return (
    <View
      className={clsx([
        "flex overflow-hidden rounded-3xl bg-oppsBlack",
        props.horizontal
          ? "flex-row items-center space-x-3"
          : "lg:flex-1 p-5 items-center space-y-3 lg:flex-row lg:p-0 lg:h-72",
        _className,
      ])}
      style={style}>
      {!props.hideAvatar && (
        <SafeImage
          fallback="avatar"
          className={clsx(
            "aspect-square bg-white",
            props.horizontal
              ? "flex flex-1 rounded-none"
              : "h-[112px] rounded-full lg:rounded-none lg:h-full",
          )}
          source={{
            uri: props.avatar,
          }}
        />
      )}
      <View
        className={clsx(
          props.horizontal
            ? "flex-1 items-start px-2 py-3"
            : "items-center space-y-2 lg:items-start lg:p-10 lg:flex-1",
        )}>
        <View className="flex flex-row items-center justify-center space-x-2">
          <Paragraph weight="bold" _className={clsx("text-2xl text-oppsRed p-0 leading-[28px]")}>
            {props.name}
          </Paragraph>
        </View>
        {Boolean(props.balance) && (
          <View
            className={clsx(props.horizontal ? "items-start" : "flex items-center lg:items-start")}>
            <Paragraph _className="text-oppsGold">Bankroll</Paragraph>
            <View className="flex flex-row items-end space-x-1">
              <Paragraph _className="text-3xl">{formatCurrency(props.balance)}</Paragraph>
              <Paragraph _className="text-md leading-7">ZBK</Paragraph>
            </View>
          </View>
        )}
      </View>
      {(props.secondaryActions || props.actions) && (
        <View className="lg:h-full lg:p-10">
          <View>{props.secondaryActions}</View>
          <View>{props.actions}</View>
        </View>
      )}
      {props.ending}
    </View>
  );
};
